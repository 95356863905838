import { Container, Row, Col, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../context/authContext';
import ReactCodeInput from 'react-verification-code-input';
import { useHistory } from "react-router-dom";

const Verify = () => {
  const { state, verify, verifyReset, clearErrorMessage } = useContext(AuthContext);
  const history = useHistory();

  async function codeCheck(code) {
    clearErrorMessage();
    const response = await verify({email: state.verified.id, code: code, token: state.token});

    if (response) {
      history.push("/myAccount");
    }
    else {
      alert("Wrong code")
    }
  }


  async function newCode() {
    await verifyReset({email: state.verified.id, token: state.token});
    alert("Code resent. Please check your email for the code")
  }
  return (
    <Container className="text-center" style={{marginTop:"200px"}}>
    <Meta title="PUCHO - Verify your Account"/>
      <h2>A verification code has been sent to your email.</h2>
      <h4>Please wait a few minutes for the email to appear.</h4>
      <Container className="pt-4" style={{display: "flex", justifyContent: "center"}}>
        <ReactCodeInput
          type="number"
          fields={5}
          onComplete={codeCheck}
          autoFocus
        />
      </Container>
      <Container className="pt-4">
        <Button variant="outline-dark" onClick={newCode}>Resend code</Button>
      </Container>
    </Container>
  )
}

export default Verify
