import { Card, Container } from 'react-bootstrap'
import { useHistory } from "react-router-dom";

const FeaturedCard = (props) => {
  const history = useHistory();

  async function handleClick() {
    history.push("/listing/"+props.url+"/" + props.name.replace(/\s+/g, '-').toLowerCase());
  }

  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Card style={{ width: '90%', cursor: "pointer", backgroundColor:"white"}} className="bg-light text-black text-center" tag="a" onClick={()=>handleClick()}>
        <div style={{height: "200px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white"}}>
          <Card.Img variant="top" src={props.img} />
        </div>
        <Card.Body>
          <Container style={{height: "75px",  display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white"}}>
            <div style={{fontSize: "14px", fontWeight: "bold"}}>{props.name}</div>
          </Container>
          <Container style={{height: "25px",  display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white"}}>
            <div style={{fontSize: "12px" }}>{props.type}</div>
          </Container>
        </Card.Body>
      </Card>
    </div>
)
}


export default FeaturedCard
