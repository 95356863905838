import Meta from '../components/Meta'

import {Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import {Context as ListingContext} from '../context/listingContext';
import {Context as AuthContext} from '../context/authContext';
import ReCAPTCHA from "react-google-recaptcha";
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaLinkedin,
  } from 'react-icons/fa'
import './Listing.css'
import { useContext, useState } from 'react';
import {
  isDesktop,
  isMobile
} from "react-device-detect";

const Contact = () => {
  const {
        send_email
  } = useContext(ListingContext);

  const { recaptcha } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  const [robotCheck, setRobotCheck] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity()===true) {
      send_email({ID: "a87kjn3a8lci", to: "info@pucho.ca",  email:form.email.value, name: form.name.value, body: form.body.value});
      alert("Your message has been sent! You will receive a reply in the inbox of your provided email address.")
    }
    setValidated(true);
  };
  async function onChange(value) {
    console.log("Captcha value:", value);
    const response = await recaptcha(value);

    if (response.success === true) {
      setRobotCheck(true);
    }
  }
  return (
    <div style={{
      backgroundImage: `url("/contact.jpg")`, backgroundSize: "cover", marginTop: "90px", textAlign: "center"
    }}>
      <Meta title={"Pucho - Contact Us"}/>
      <div className="pt-5 pb-5">
        <h1>Contact Us</h1>
      </div>
      <div className="pb-5" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Row>
          <Col>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className= "pt-2" controlId="contactForm.email">
                <Form.Control name="email" type="email" placeholder="Your Email" required />
              </Form.Group>
              <Form.Group className="pt-2" controlId="contactForm.name">
                <Form.Control name="name" type="text" placeholder="Your Name" required />
              </Form.Group>
              <Form.Group className="pt-4" controlId="contactForm.body">
                <Form.Control name="body" as="textarea" rows={5} placeholder="Message" required />
              </Form.Group>
              <div className="pt-2 text-center" >
                <Button type="submit" variant="dark" disabled={!robotCheck}>Send</Button>
                <Container className="pt-2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <ReCAPTCHA
                    sitekey="6LcQOKAcAAAAAIG9Wel7t5smUJIC0uX2fw9PAtQ-"
                    onChange={onChange}
                  />
                </Container>
              </div>
            </Form>
          </Col>
          <Col>
            <Card style={{backgroundColor: "transparent", textAlign: "left"}}>
              <Card.Body>
                <div style={{fontWeight: "bold", fontSize: "13px"}}>For General Inquiries</div>
                info@pucho.ca

                <div style={{fontWeight: "bold", fontSize: "13px", marginTop: "20px"}}>For Support</div>
                support@pucho.ca

                <div style={{fontWeight: "bold", fontSize: "13px", marginTop: "20px"}}>Social</div>
                <FaInstagram  size={20} />
                &ensp;&ensp;
                <FaTwitter  size={20}/>
                &ensp;&ensp;
                <FaLinkedin  size={20}/>
                <div>#pucho</div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Contact
