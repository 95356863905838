import React, { useState, useEffect, useRef } from "react";
import "./SearchLocationInput.css"

const SearchLocationInput = ({setLat, setLng }) => {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  let autoComplete;
  const apiKey = "AIzaSyB7JqenDvjDqDoew8wcuk1Kb6CIaac_z28"

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  // handle when the script is loaded we will assign autoCompleteRef with google maps place autocomplete
  function handleScriptLoad(updateQuery, autoCompleteRef) {
    // assign autoComplete with Google maps place one time
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "ca" } }
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    //console.log(addressObject.geometry.location.lng());
    setLat(addressObject.geometry.location.lat());
    setLng(addressObject.geometry.location.lng());
    setQuery(addressObject.formatted_address);
  }

    useEffect(() => {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        () => handleScriptLoad(setQuery, autoCompleteRef)
      );
    }, []);

  return (
    <div className="search-location-input">
      <input
        autoComplete="off"
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder="Enter address"
        value={query}
      />
    </div>
  );
}

export default SearchLocationInput;
