import { Container, Row, Col, Card, Form, Image, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../context/authContext';
import { useHistory } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';

const Signup = () => {
  const { state, signup, signinGoogle } = useContext(AuthContext);
  const [validated, setValidated] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState();
  const [password2Valid, setPassword2Valid] = useState();
  const [emailValid, setEmailValid] = useState();
  const history = useHistory();

  async function responseGoogle(token) {
    const response = await signinGoogle({token: token.tokenId});
    console.log(response);
    if (response.verified) {
      history.push("/myaccount");
    } else {
      alert("Something went wrong")
    }
  }

  async function handleSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
      if (passwordValid && password2Valid && emailValid) {
        const response = await signup({email:email, password: password})
        if (response) {
          history.push("/verify")
      } else {
        setEmailValid(false);
        alert("Email already in use!")
      }
    }
    else {
      alert("Please complete all fields")
    }
  }

  function passwordChange1(event) {
    var check = event.target.value
    setPasswordValid(false);
    setPassword(check);
    if (check.length < 8) return
    if(/\d/.test(check)==false) return
    if (check.toUpperCase() == check) return
    if (check.toLowerCase() == check) return
    setPasswordValid(true);
  }
  function passwordChange2(event) {
    var check = event.target.value
    setPassword2Valid(false);
    if (password == check) {
      setPassword2Valid(true);
    }
  }

  function emailChange(event) {
    var check = event.target.value;
    setEmail(check);
    setEmailValid(false);
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(check)) {
      setEmailValid(true);
    }
  }

  return (
    <Row className="text-center" style={{marginTop:"90px"}}>
      <Meta title="PUCHO - Sign Up Today!"/>
      <Col sm={8}>
        <Image style={{ objectFit: "cover", width:"100%" }} fluid src="signup.jpg" />
      </Col>
    <Col sm={4}>
        <Card>
          <Card.Body>
           <Card.Title className="pb-3 pt-5"><h1>Join Pucho today!</h1></Card.Title>
           <hr />
           <Container className="pt-3 pb-3">
             <GoogleLogin
               clientId="692515347689-9opro7d0dsedfdlk4nhufoetoo8qb1rv.apps.googleusercontent.com"
               buttonText="Sign Up with Google"
               onSuccess={responseGoogle}
               onFailure={responseGoogle}
               cookiePolicy={'single_host_origin'}
               isSignedIn={false} />
          </Container>

           <hr />
           Or sign in with your email and password
           <Form className="pt-5" noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Email address</Form.Label>
                <Form.Control required name="email" type="email" placeholder="Enter email" isInvalid={!emailValid} isValid={emailValid} onChange={emailChange}/>
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3 pt-3" controlId="formBasicPassword">
                <Form.Label className="fw-bold">Password</Form.Label>
                <Form.Control required name="password" type="password" placeholder="Password" isInvalid={!passwordValid} isValid={passwordValid} onChange={passwordChange1} />
                <br />
                <Form.Control required type="password" placeholder="Reenter Password" isInvalid={!password2Valid} isValid={password2Valid} onChange={passwordChange2} />
                <Form.Text className="text-muted">
                  8-character minimum with a capital letter and number.
                </Form.Text>
              </Form.Group>
              <Container className="pt-3">
                By signing up, you agree to our Terms of Service and Privacy Policy.
              </Container>
              <Container className="pt-2">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Container>
              <Row className="pt-5">
                <Card.Link href="/login">Already have an account?</Card.Link>
              </Row>
            </Form>
              </Card.Body>
              <Container className="pt-5 pb-5" />
          </Card>
        </Col>
    </Row>
  )
}

export default Signup
