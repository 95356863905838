import { Nav, Container, Form, Button } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import './Menu.css'
import Cookies from 'universal-cookie';
import { Context as AuthContext } from '../context/authContext';

import { SearchBar } from "./Search"
import { useEffect, useContext } from 'react';

const Menu = () => {
    const cookies = new Cookies();
    const { state: auth,authCheck } = useContext(AuthContext);
    var token = cookies.get('token');


    useEffect( () => {
      token = auth.token;
    }, [auth.token]);

  return (
            <Navbar className="color-nav" variant="light" expand="md" fixed="top">
              <Container>
                <Navbar.Brand href="/">
                <img
                  alt=""
                  src="/logo.png"
                  width="auto"
                  height="50"
                  className="d-inline-block align-middle"
                  />{' '}
                   PUCHO
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <SearchBar />
                  </Nav>
                  <Nav className="vertical-center">
                    <Nav.Link href="/explore/all/Toronto (GTA)"><Button variant="dark" size="sm">Explore</Button></Nav.Link>
                    { token ?
                      <Nav.Link href="/myaccount">My Account</Nav.Link>
                     :<>
                        <Nav.Link href="/login">Log In</Nav.Link>
                        <Nav.Link href="/signup"><Button variant="outline-dark" size="sm">Sign Up</Button></Nav.Link>
                      </>
                    }
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
  )
}

export default Menu
