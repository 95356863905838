import { Form, Row, Col } from 'react-bootstrap'
import { useState } from 'react';

const Time = ({day, time, setTime, id}) => {
  function handleChange1(value) {
    setTime({...time, open: value});
  }
  function handleChange2(value) {
    setTime({...time, close: value});
  }
  function handleChange3() {
    setTime({...time, isOpen: show});
    setShow(!show)
  }
  const [show, setShow] = useState(false);
  return(
    <>
      <Row style={{paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "left"}}>
        <Col md="2" style={{fontWeight: "bold", paddingBottom: "10px", paddingTop: "10px"}}>{day}</Col>
        <Col md="2">
          <Form.Check
            type="checkbox"
            id={id}
            label="Closed"
            onChange={handleChange3}
            value={!time.isOpen}
            checked={!time.isOpen}
          />
        </Col>

      { time.isOpen ?
        <>
          <Form.Group as={Col} md="2" controlId="validationCustom03">
                <Form.Control as="select" aria-label="Default select example" onChange={e => handleChange1(e.target.value)} value={time.open} defaultValue={"9:00 AM"}>
                  <Options />
                </Form.Control>
          </Form.Group>
        <Col md="1" className="text-center">
          to
        </Col>
        <Form.Group as={Col} md="2" controlId="validationCustom04">
          <Form.Control as="select" aria-label="Default select example" onChange={e => handleChange2(e.target.value)} value={time.close} defaultValue={"9:00 PM"}>
            <Options />
          </Form.Control>
        </Form.Group>
        </> : <></>
      }
      </Row>
    </>
  )
}
const Options = (props) => {
  return (
    <>
      <option value="12:00 AM">12:00 AM</option>
      <option value="12:30 AM">12:30 AM</option>
      <option value="1:00 AM">1:00 AM</option>
      <option value="1:30 AM">1:30 AM</option>
      <option value="2:00 AM">2:00 AM</option>
      <option value="2:30 AM">2:30 AM</option>
      <option value="3:00 AM">3:00 AM</option>
      <option value="3:30 AM">3:30 AM</option>
      <option value="4:00 AM">4:00 AM</option>
      <option value="4:30 AM">4:30 AM</option>
      <option value="5:00 AM">5:00 AM</option>
      <option value="5:30 AM">5:30 AM</option>
      <option value="6:00 AM">6:00 AM</option>
      <option value="6:30 AM">6:30 AM</option>
      <option value="7:00 AM">7:00 AM</option>
      <option value="7:30 AM">7:30 AM</option>
      <option value="8:00 AM">8:00 AM</option>
      <option value="8:30 AM">8:30 AM</option>
      <option value="9:00 AM">9:00 AM</option>
      <option value="9:30 AM">9:30 AM</option>
      <option value="10:00 AM">10:00 AM</option>
      <option value="10:30 AM">10:30 AM</option>
      <option value="11:00 AM">11:00 AM</option>
      <option value="11:30 AM">11:30 AM</option>
      <option value="12:00 PM">12:00 PM</option>
      <option value="12:30 PM">12:30 PM</option>
      <option value="1:00 PM">1:00 PM</option>
      <option value="1:30 PM">1:30 PM</option>
      <option value="2:00 PM">2:00 PM</option>
      <option value="2:30 PM">2:30 PM</option>
      <option value="3:00 PM">3:00 PM</option>
      <option value="3:30 PM">3:30 PM</option>
      <option value="4:00 PM">4:00 PM</option>
      <option value="4:30 PM">4:30 PM</option>
      <option value="5:00 PM">5:00 PM</option>
      <option value="5:30 PM">5:30 PM</option>
      <option value="6:00 PM">6:00 PM</option>
      <option value="6:30 PM">6:30 PM</option>
      <option value="7:00 PM">7:00 PM</option>
      <option value="7:30 PM">7:30 PM</option>
      <option value="8:00 PM">8:00 PM</option>
      <option value="8:30 PM">8:30 PM</option>
      <option value="9:00 PM">9:00 PM</option>
      <option value="9:30 PM">9:30 PM</option>
      <option value="10:00 PM">10:00 PM</option>
      <option value="10:30 PM">10:30 PM</option>
      <option value="11:00 PM">11:00 PM</option>
      <option value="11:30 PM">11:30 PM</option>
   </>
  )
}

export default Time
