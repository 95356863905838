import { Card, Row, Col, CardGroup, ListGroup, Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import {
  isDesktop,
  isMobile
} from "react-device-detect";

import {
    FaFacebook,
    FaTwitter,
    FaYoutube,
    FaInstagram,
    FaVimeo,
    FaLinkedin,
    FaShareAlt,
    FaPhoneAlt,
    FaGlobe,
    FaMapMarkerAlt,
    FaEnvelope
  } from 'react-icons/fa'

export const Social = (props) => {
  function urlCheck(url) {
    if (url.includes("://")) {

    }
    else {
        url = 'http://' + url;
    }
    return url
  }
  function handleClick() {
      if (navigator.share) {
        navigator.share({
          title: 'Check out this listing on Pucho!',
          url: window.location.href
        }).then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
      } else {
        // fallback
        //navigator.clipboard.writeText(window.location.href)
        //alert(window.location.href + " has been coped to the clipboard!")
      }
  }
  function findIcon(socialURL) {
    if (socialURL.includes('instagram')) {
      socialURL = <FaInstagram size={props.small ? 20:30} />;
    } else if (socialURL.includes('facebook')) {
      socialURL = <FaFacebook size={props.small ? 20:30} />;
    } else if (socialURL.includes('twitter')) {
      socialURL = <FaTwitter size={props.small ? 20:30} />;
    } else if (socialURL.includes('vimeo')) {
      socialURL = <FaVimeo size={props.small ? 20:30} />;
    } else if (socialURL.includes('linkedin')) {
      socialURL = <FaLinkedin size={props.small ? 20:30} />;
    } else if (socialURL.includes('youtube')) {
      socialURL = <FaYoutube size={props.small ? 20:30} />;
    }
    else {
      socialURL = '';
    }
    return socialURL;
  }
  return (
    <div>
      {props.links.map((l, i) => (
        <div key={i} style={{display:"inline-block"}}>&ensp;&ensp;<a href={urlCheck(l)} className="text-black" target="_blank" rel="noopener noreferrer">{findIcon(l)}</a></div>
      ))}

      { props.share ? <>&ensp;&ensp;<FaShareAlt style={{cursor: "pointer"}} onClick={handleClick} className="text-black" size={props.small ? 15:25} /></> : <></> }
    </div>
  )
}


export const ContactCard = (props) => {
  const [reveal, setReveal] = useState(false);
  const [phone, setPhone] = useState(false);
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  function urlCheck(url) {
    if (url.includes("://")) {

    }
    else {
        url = 'http://' + url;
    }
    return url
  }
  function urlScrub(url) {
        var clean = url.replace(/(^\w+:|^)\/\//, '');
    return clean
  }

  return (
      <Card>

          <ListGroup variant="flush">
            {props.listing.phone ?
                <ListGroup.Item>
                  <ListGroup horizontal flush>
                    <ListGroup.Item style={{border: "none", paddingLeft: "1px", paddingRight: "1px"}}>
                      <FaPhoneAlt />
                    </ListGroup.Item>
                    <ListGroup.Item style={{border: "none", display:"flex", alignItems:"center"}}>
                      {
                        phone === false ?
                          <Button onClick={()=> setPhone(true)} variant="link" size="sm">Click to reveal</Button>
                          :
                          <a href={"tel:" + props.listing.phone}>{formatPhoneNumber(props.listing.phone)}</a>
                      }
                    </ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item> : <></>}
            {props.listing.website ? <ListGroup.Item><ListGroup horizontal flush><ListGroup.Item style={{border: "none", paddingLeft: "1px", paddingRight: "1px"}}><FaGlobe /></ListGroup.Item><ListGroup.Item style={{border: "none", display:"flex", alignItems:"center"}}><a href ={urlCheck(props.listing.website)} target="_blank" rel="noopener noreferrer">{urlScrub(props.listing.website)}</a></ListGroup.Item></ListGroup></ListGroup.Item>: <></>}
            <ListGroup.Item>
              <ListGroup horizontal flush>
                <ListGroup.Item style={{border: "none", paddingLeft: "1px", paddingRight: "1px"}}>
                  <FaEnvelope />
                </ListGroup.Item>
                <ListGroup.Item style={{border: "none", display:"flex", alignItems:"center"}}>
                  {
                    reveal === false ?
                      <Button onClick={()=> setReveal(true)} variant="link" size="sm">Click to reveal</Button>
                      :
                      <a href={"mailto:"+props.listing.email}>{props.listing.email}</a>
                  }
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            {props.listing.address ? <ListGroup.Item><ListGroup horizontal flush><ListGroup.Item style={{border: "none", paddingLeft: "1px", paddingRight: "1px"}}><FaMapMarkerAlt /></ListGroup.Item><ListGroup.Item style={{border: "none", display:"flex", alignItems:"center"}}>{props.listing.address}</ListGroup.Item></ListGroup></ListGroup.Item>:<></>}
          </ListGroup>

      </Card>
  )
}
