import { Container, Row, Col, Form, Card, Image, Button, InputGroup, Modal } from 'react-bootstrap'
import Meta from '../components/Meta'
import { Prompt } from 'react-router'
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../context/authContext';
import {Context as ListingContext} from '../context/listingContext';
import { useHistory } from "react-router-dom";
import Time from "../components/Time.js"
import {PhotoPicker, LogoPicker} from "../components/PhotoPicker.js"
import SearchLocationInput from "../components/SearchLocationInput.js"
import Services from "../components/Services.js"

const AddListing = () => {
  const { state, authCheck, get_categories, get_cities } = useContext(AuthContext);
  const { state: listing, add_listing, update_listing, get_sig, cloudinaryUpload, cloudinaryDelete, clearListing  } = useContext(ListingContext);
  const [validated, setValidated] = useState(false);
  const [editCheck, setEditCheck] = useState(false);
  const [infoValid, setInfoValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [typeValid, setTypeValid] = useState(false);
  const [cityValid, setCityValid] = useState(false);
  const [otherCategory, setOtherCategory] = useState(false);
  const history = useHistory();
  const [photoRef, setPhotoRef] = useState('');
  const [logoRef, setLogoRef] = useState('');
//Social media
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [youtube, setYoutube] = useState('');
  const [vimeo, setVimeo] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [website, setWebsite] = useState('');
  const [categories, setCategories] = useState(["Select"]);
  const [cities, setCities] = useState(["Select"]);

//Contact Info
  const [phone, setPhone] = useState('');
  const [type, setType] = useState(' ');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');
  const [city, setCity] = useState('');
  const [other, setOther] = useState('');
  const [services, setServices] = useState([]);

//Day hours states
  const [monday, setMonday] = useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});
  const [tuesday, setTuesday] = useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});
  const [wednesday, setWednesday] = useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});
  const [thursday, setThursday] =useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});
  const [friday, setFriday] = useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});
  const [saturday, setSaturday] = useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});
  const [sunday, setSunday] = useState({open:"9:00 AM", close:"9:00 PM", isOpen:true});

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const [hours, setHours] = useState(false);
  const [location, setLocation] = useState(false);
  const [gmaps, setGmaps] = useState(false);
  const [social, setSocial] = useState(false);
  const [servicesToggle, setServicesToggle] = useState(false);
  const [photos, setPhotos] = useState({photo1: ' ', photo2: ' ', photo3: ' '});
  const [photosURL, setPhotosURL] = useState({photo1: ' ', photo2: ' ', photo3: ' '});
  const [logoURL, setLogoURL] = useState(' ');
  const [logo, setLogo] = useState(' ');

  useEffect( async() => {
      const load = await get_categories();
      await setCategories(load);
      const load2 = await get_cities();
      await setCities(load2);
      const status = await authCheck();
      if (status == "") {
        setValidated(true);
        history.push("/login")
      } else {
        if (listing.listing !== null) {
          setEditCheck(true);
          setAddress(listing.listing.address);
          setServices(listing.listing.keywords);
          setName(listing.listing.name);
          setInfo(listing.listing.info);
          onPhoneChange(listing.listing.phone);
          setLat(listing.listing.mapCords.lat);
          setLng(listing.listing.mapCords.lng);
          setType(listing.listing.type);
          setCity(listing.listing.city);
          setEmail(listing.listing.email);
          setWebsite(listing.listing.website);
          setLogoURL(listing.listing.logo);
          setLogoRef(listing.listing.logo);
          if (listing.listing.address) {
            setLocation(true);
          }
          if (listing.listing.mapCords.lat) {
            setGmaps(true);
          }
          if (listing.listing.keywords.length > 0) {
            setServicesToggle(true);
          }
          if (listing.listing.photoURL) {
            if (listing.listing.photoURL.length === 0) { setPhotosURL({photo1: ' ', photo2: ' ', photo3:' '}); }
            if (listing.listing.photoURL.length === 1) { setPhotosURL({photo1: listing.listing.photoURL[0], photo2: ' ', photo3: ' '}); }
            if (listing.listing.photoURL.length === 2) { setPhotosURL({photo1: listing.listing.photoURL[0], photo2: listing.listing.photoURL[1], photo3: ' '}); }
            if (listing.listing.photoURL.length === 3) { setPhotosURL({photo1: listing.listing.photoURL[0], photo2: listing.listing.photoURL[1], photo3: listing.listing.photoURL[2]}); }

            if (listing.listing.photoURL.length === 0) { setPhotoRef({photo1: ' ', photo2: ' ', photo3: ' '}); }
            if (listing.listing.photoURL.length === 1) { setPhotoRef({photo1: listing.listing.photoURL[0], photo2: ' ', photo3: ' '}); }
            if (listing.listing.photoURL.length === 2) { setPhotoRef({photo1: listing.listing.photoURL[0], photo2: listing.listing.photoURL[1], photo3: ' '}); }
            if (listing.listing.photoURL.length === 3) { setPhotoRef({photo1: listing.listing.photoURL[0], photo2: listing.listing.photoURL[1], photo3: listing.listing.photoURL[2]}); }
          }

          if (listing.listing.hours !== '') {
            setHours(true);
            setMonday({open: listing.listing.hours.monday.open, close: listing.listing.hours.monday.close, isOpen: listing.listing.hours.monday.isOpen});
            setTuesday({open: listing.listing.hours.tuesday.open, close: listing.listing.hours.tuesday.close, isOpen: listing.listing.hours.tuesday.isOpen});
            setWednesday({open: listing.listing.hours.wednesday.open, close: listing.listing.hours.wednesday.close, isOpen: listing.listing.hours.wednesday.isOpen});
            setThursday({open: listing.listing.hours.thursday.open, close: listing.listing.hours.thursday.close, isOpen: listing.listing.hours.thursday.isOpen});
            setFriday({open: listing.listing.hours.friday.open, close: listing.listing.hours.friday.close, isOpen: listing.listing.hours.friday.isOpen});
            setSaturday({open: listing.listing.hours.saturday.open, close: listing.listing.hours.saturday.close, isOpen: listing.listing.hours.saturday.isOpen});
            setSunday({open: listing.listing.hours.sunday.open, close: listing.listing.hours.sunday.close, isOpen: listing.listing.hours.sunday.isOpen});

          }

          if (listing.listing.social) {
            listing.listing.social.map((l,i) => {
              setSocial(true);
              if (listing.listing.social[i].includes('instagram')) {
                setInstagram(listing.listing.social[i]);
              } else if (listing.listing.social[i].includes('facebook')) {
                setFacebook(listing.listing.social[i]);
              } else if (listing.listing.social[i].includes('twitter')) {
                setTwitter(listing.listing.social[i]);
              } else if (listing.listing.social[i].includes('youtube')) {
                setYoutube(listing.listing.social[i]);
              } else if (listing.listing.social[i].includes('vimeo')) {
                setVimeo(listing.listing.social[i]);
              } else if (listing.listing.social[i].includes('linkedin')) {
                setLinkedIn(listing.listing.social[i]);
              }
            });
          }
        }
      }
    }, []);

  async function getValidationState() {
    if (name !== '') { setNameValid(true) } else { setNameValid(false) }
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) { setEmailValid(true) } else { setEmailValid(false) }
    if (info !== '') { setInfoValid(true) } else { setInfoValid(false) }
    if (type !== ' ') { setTypeValid(true) } else { setTypeValid(false) }

    console.log(nameValid)
    console.log(infoValid);
    console.log(phoneValid);
    console.log(typeValid);

    if (nameValid && infoValid && emailValid && typeValid) {
      alert("Success");
      return true
    } else {
      return false
    }
  }

  function handleClose() {
    history.push('/myaccount');
  }

  async function handleSubmit(event) {
      event.preventDefault();
      event.stopPropagation();

        if (editCheck) {
            const newListing = await buildListingObject();
            var responseEdit = await update_listing(newListing, listing.listing._id);
            setValidated(true);
        } else {
            const newListing = await buildListingObject();
            await add_listing(newListing);
            setValidated(true);
        }
  }

  const buildListingObject = async () => {
    var socialArray = [];
    var photoTemp = [];
    var mapObject
    var logoObject = " "
    var hoursObject = ""

    if (hours) {
      hoursObject = ({monday, tuesday, wednesday, thursday, friday, saturday, sunday });
    }

    if (instagram !== '') { socialArray.push(instagram); }
    if (twitter !== '') { socialArray.push(twitter); }
    if (facebook !== '') { socialArray.push(facebook); }
    if (youtube !== '') { socialArray.push(youtube); }
    if (vimeo !== '') { socialArray.push(vimeo); }
    if (linkedIn !== '') { socialArray.push(linkedIn); }


    if (editCheck) {
      if (photosURL.photo1 !== photoRef.photo1 && photoRef.photo1 !== ' ') {await deleteImage(photoRef.photo1);}
      if (photosURL.photo2 !== photoRef.photo2 && photoRef.photo2 !== ' ') {await deleteImage(photoRef.photo2);}
      if (photosURL.photo3 !== photoRef.photo3 && photoRef.photo3 !== ' ') {await deleteImage(photoRef.photo3);}

      if (photosURL.photo1 !== photoRef.photo1 && photosURL.photo1 !== ' ') {photoTemp.push(await uploadImage(photos.photo1));}
      else { photoTemp.push({url: photosURL.photo1}); }
      if (photosURL.photo2 !== photoRef.photo2 && photosURL.photo2 !== ' ') {photoTemp.push(await uploadImage(photos.photo2));}
      else { photoTemp.push({url: photosURL.photo2}); }
      if (photosURL.photo3 !== photoRef.photo3 && photosURL.photo3 !== ' ') {photoTemp.push(await uploadImage(photos.photo3));}
      else { photoTemp.push({url: photosURL.photo3}); }

      if (logoURL !== logoRef && logoRef !== ' ') {await deleteImage(logoRef);}

      if (logoURL !== logoRef && logoURL !== ' ') {
        const logoUpload = await uploadImage(logo);
        logoObject = logoUpload.url;
      } else {
          logoObject = logoURL;
      }

      } else {
        if (photos.photo1 !== ' ') {photoTemp.push(await uploadImage(photos.photo1));}
        if (photos.photo2 !== ' ') {photoTemp.push(await uploadImage(photos.photo2));}
        if (photos.photo3 !== ' ') {photoTemp.push(await uploadImage(photos.photo3));}
        if (logoURL !== ' ') {
          const logoUpload = await uploadImage(logo);
          logoObject = logoUpload.url;
        }
      }

      var photoObject = []
      photoTemp.map((l,i) => {
        if (l.url !== ' ' && l.url !== undefined) {photoObject.push(l.url);}
      });
      if (location) {
        mapObject = {lat: lat, lng: lng}
      } else {
        mapObject = ({lat: '', lng: ''});
      }

    var newListing = {name, phone, email, city, info, address, keywords: services, socialArray, hoursObject, photoObject, logoObject, mapObject, website};

    if (otherCategory) {
      newListing = {...newListing, type: other}
    } else {
      newListing = {...newListing, type}
    }

    console.log(newListing)
    return newListing
  }
  const uploadImage = async (file) => {
    var eager = '';
    var signature = await get_sig(eager,file.name);
    var response = await cloudinaryUpload(file, signature.sig, signature.timestamp);
    return response;
  };

  const deleteImage = async (file) => {
    var publicID = file.split('/');
    var curPhoto = publicID[publicID.length - 1].split('.');
    await cloudinaryDelete(curPhoto[0]);
  };
  const onPhoneChange = (text) => {
    var cleaned = ('' + text).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : ''),
            number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        setPhone(number);
        setPhoneValid(true);
        return;
    }
    setPhoneValid(false);
    setPhone(text);
  };
  return (
    <Container className="" style={{marginTop:"90px"}}>
        <Prompt when={!validated} message="Are you sure you want to leave? Any unsubmitted data will be lost."/>

        <Card>
          <Card.Body>
           <Card.Title className="pb-3 pt-5">
            { editCheck ?
              <>
                <Meta title="PUCHO - Edit Listing"/>
                <h1>Edit Listing</h1>
              </>
              :
              <>
                <Meta title="PUCHO - Add a Listing"/>
                <h1>Add a listing today!</h1>
              </>
            }
            </Card.Title>
           <hr />
           <div className="pt-3 pb-3"><h3>Mandatory Fields</h3></div>
           <Form onSubmit={handleSubmit}>
             <Row className="mb-3">
               <Form.Group as={Col} md="4" controlId="validationName">
                 <Form.Label>Business name*</Form.Label>
                 <Form.Control
                   required
                   type="text"
                   placeholder="Name"
                   onChange={e => setName(e.target.value)}
                   isValid={nameValid}
                   value={name}
                 />
                 <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
               </Form.Group>
               <Form.Group as={Col} md="4" controlId="validationCategory">
                 <Form.Label>Category*</Form.Label>
                 <Form.Select
                      aria-label="Default select example"
                    required
                    onChange={e => {
                        setType(e.target.value);
                        if (e.target.value === "Other") {
                          setOtherCategory(true);
                        } else {
                          setOtherCategory(false);
                          setOther("");
                        }
                      }
                    }
                    isValid={typeValid}
                    value={type}
                  >
                  <option></option>
                  {categories.map((l, i) => (
                    <option key={i} value={l}>{l}</option>
                  ))}
                    <option value="Other">Other</option>
                </Form.Select>
                 <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
               </Form.Group>
               <Form.Group as={Col} md="4">
                <Form.Label>{otherCategory ? "Input custom category" : "*" }</Form.Label>
                 <Form.Control
                    disabled={!otherCategory}
                    onChange={e => setOther(e.target.value)}
                    value={other}
                    required />
               </Form.Group>
              </Row>
              <Row>
               <Form.Group as={Col} md="4" controlId="validationEmail">
                 <Form.Label>Email*</Form.Label>
                 <Form.Control
                   required
                   type="email"
                   placeholder="Email"
                   onChange={e => setEmail(e.target.value)}
                   isValid={emailValid}
                   value={email}
                 />
                 <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
               </Form.Group>
               <Form.Group as={Col} md="4" controlId="validationCity">
                 <Form.Label>Region*</Form.Label>
                 <Form.Select
                   required
                   aria-label="Defaul select example"
                   onChange={e => setCity(e.target.value)}
                   isValid={cityValid}
                   value={city}
                 >
                 <option></option>
                 {cities.map((l, i) => (
                   <option key={i} value={l.city}>{l.city}</option>
                 ))}
               </Form.Select>
                 <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
               </Form.Group>
             </Row>
             <Row className="mb-3 pb-3">
               <Form.Group className="pt-4" controlId="validationInfo">
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                  name="body"
                  as="textarea"
                  rows={5}
                  placeholder="Tell us a little about the business"
                  onChange={e => setInfo(e.target.value)}
                  isValid={infoValid}
                  value={info}
                  required />
               </Form.Group>
             </Row>
             <hr />
             <Row className="mb-3 pt-3 pb-3">
                <div className="pt-3 pb-3"><h3>Optional Contact Info</h3></div>
                <Form.Group as={Col} md="4" >
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    value={website}
                    onChange={e => setWebsite(e.target.value)} />
                </Form.Group>
               <Form.Group as={Col} md="4" controlId="validationNumber">
                 <Form.Label>Phone Number</Form.Label>
                 <Form.Control
                    type="text"
                    onChange={e => onPhoneChange(e.target.value)}
                    value={phone}
                    placeholder="XXX XXX-XXXX"
                    isValid={phoneValid}
                    />
                 <Form.Control.Feedback type="invalid">
                   Please provide a valid phone number.
                 </Form.Control.Feedback>
               </Form.Group>
             </Row>
             <hr />
             <Row className="mb-3 pt-3">
               <Container className="pt-3 pb-3">
                <div><h3>Location</h3></div>
                <Form.Check
                  type="checkbox"
                  id="locationControl"
                  onChange={() => setLocation(!location)}
                  label="Include"
                  value={location}
                  checked={location}
                />
              </Container>
              { location ?
                <Container>
                <Form.Group as={Col} md="4" className="pb-3" >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="address"
                    placeholder="Address"
                    value={address}
                    onChange={e => setAddress(e.target.value)} />
                </Form.Group>
                <Form.Check
                  type="checkbox"
                  id="gmapsControl"
                  onChange={() => setGmaps(!gmaps)}
                  label="Would you like to display a map via Google Maps?"
                  value={gmaps}
                  checked={gmaps}
                />

                { gmaps &&
                    <>
                    <div className="pt-3 pb-3">
                      Please enter and select the closest address on Google Maps. You must click on the autocompleted address to register your map location.
                    </div>
                   <SearchLocationInput setLat={setLat} setLng={setLng} onChange={() => null} />
                   <div className="pt-2">Latitude: {lat}, Longitude: {lng}</div>
                   </>
                }
                </Container>
                : <></>
              }
            </Row>
            <hr />
            <Row className="mb-3 pt-3">
              <Container className="pt-3 pb-3">
               <div><h3>Services</h3></div>
               <Form.Check
                 type="checkbox"
                 id="serviceControl"
                 onChange={() => setServicesToggle(!servicesToggle)}
                 label="Include"
                 value={servicesToggle}
                 checked={servicesToggle}
               />
             </Container>
             { servicesToggle &&
                <Services services={services} setServices={setServices} />
             }
           </Row>
           <hr />
            <Row className="mb-3 pt-3 pb-3">
              <Container className="pt-3 pb-3">
               <div><h3>Social Media</h3></div>
               <Form.Check
                 type="checkbox"
                 id="socialMediaControl"
                 onChange={() => setSocial(!social)}
                 label="Include"
                 value={social}
                 checked={social}
               />
             </Container>

             { social ?
               <>
                 <Row className="pb-3">
                  <Form.Group as={Col} controlId="validationFacebook">
                    <Form.Label>Facebook</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="www.facebook.com/xxxx"
                      value={facebook}
                      onChange={e => setFacebook(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid link.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="validationInstagram">
                    <Form.Label>Instagram</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="www.instagram.com/xxxx"
                      value={instagram}
                      onChange={e => setInstagram(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid link.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="validationTwitter">
                    <Form.Label>Twitter</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="www.twitter.com/xxxx"
                      value={twitter}
                      onChange={e => setTwitter(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid link.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="validationYoutube">
                    <Form.Label>Youtube</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="www.youtube.com/xxxx"
                      value={youtube}
                      onChange={e => setYoutube(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid link.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="pt-3">
                  <Form.Group as={Col} controlId="validationVimeo">
                    <Form.Label>Vimeo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="www.vimeo.com/xxxx"
                      value={vimeo}
                      onChange={e => setVimeo(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid link.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="validationLinkedIn">
                    <Form.Label>LinkedIn</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="www.linkedin.com/xxxx"
                      value={linkedIn}
                      onChange={e => setLinkedIn(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid link.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </> : <></>
            }
              </Row>
              <hr />

              <Row className="mb-3 pt-3 pb-3">
                  <Container>
                   <div className="pt-3 pb-3"><h3>Hours</h3></div>
                   <Form.Check
                     type="checkbox"
                     id="hoursControl"
                     onChange={() => setHours(!hours)}
                     label="Include"
                     value={hours}
                     checked={hours}
                   />
                 </Container>

                 { hours ?
                   <>
                     <Time day="Monday" id={"1"} time={monday} setTime={setMonday} />
                     <Time day="Tuesday" id={"2"} time={tuesday} setTime={setTuesday} />
                     <Time day="Wednesday" id={"3"} time={wednesday} setTime={setWednesday} />
                     <Time day="Thursday" id={"4"} time={thursday} setTime={setThursday} />
                     <Time day="Friday" id={"5"} time={friday} setTime={setFriday} />
                     <Time day="Saturday" id={"6"} time={saturday} setTime={setSaturday} />
                     <Time day="Sunday" id={"7"} time={sunday} setTime={setSunday} />
                  </> : <></>
                }
              </Row>

              <hr />
              <Row className="mb-3 pt-3">
                <Container className="pt-3 pb-3">
                 <div><h3>Logo</h3></div>
               </Container>
               <LogoPicker logo={logo} setLogo={setLogo} edit={editCheck} logoURL={logoURL} setLogoURL={setLogoURL} />
             </Row>
              <hr />
              <Row className="mb-3 pt-3">
                <Container className="pt-3 pb-3">
                 <div><h3>Images</h3></div>
               </Container>
               <PhotoPicker photos={photos} setPhotos={setPhotos} edit={editCheck} photosURL={photosURL} setPhotosURL={setPhotosURL} />
             </Row>

             <hr />
             <div className="pt-3 pb-3">
              <p>You must agree to the Pucho <a href="/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</a> in order to list on the website.</p>
              <p>In addtion, all content and pictures submitted must be in compliance with the Pucho <a href="/contentguidelines" target="_blank" rel="noopener noreferrer">Content Guideilnes</a>. Your listing submission will be reviewed by Pucho against this criteria. </p>
             </div>

             <Form.Group className="mb-3">
               <Form.Check
                 required
                 label="Agree to Terms of Service"
                 feedback="You must agree before submitting."
                 feedbackType="invalid"
               />
             </Form.Group>
             <Button type="submit">Submit form</Button>
           </Form>
              </Card.Body>
              <Container className="pt-5 pb-5" />
          </Card>

          <Modal show={validated} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Pucho</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  <p>Thanks for submitting your listing! It will now be reviewed by the Pucho team
                  before being posted to the website. You will be notified by the team when your posting is live.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
    </Container>
  )
}

export default AddListing
