import { Container, Row, Modal, Card, Form, Button } from 'react-bootstrap'
import Meta from '../components/Meta'
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../context/authContext';
import { useHistory, useParams, useLocation } from "react-router-dom";

const Reset = () => {
  let { link } = useParams();
  const { resetPassword } = useContext(AuthContext);;
  const [id, setId] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState();
  const [password2Valid, setPassword2Valid] = useState();
  const history = useHistory();
  const [show, setShow] = useState(false);

  useEffect( async() => {
    console.log(window.location.href)
    const urlParams = await new URLSearchParams(window.location.href);
    //const urlParams = "http://pucho.ca/reset/" + link;
    const arg1 = await urlParams.get('id');
    const arg2 = await urlParams.get('token');
    await setId(arg1);
    await setToken(arg2);
    const response = await resetPassword({id:arg1, token: arg2, newPassword: ""});
    console.log(response)
    if (response !== true) {
      alert ("Something went wrong");
      history.push("/");
    }
    }, []);

  async function handleSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
      if (passwordValid && password2Valid) {
        const response = await resetPassword({id:id, token: token, newPassword: password})
        if (response) {
          setShow(true);
        } else {
          alert ("Something went wrong");
          history.push("/")
        }
    }
  }

  function handleClose() {
    setShow(false);
    history.push('/myaccount');
  }

  function passwordChange1(event) {
    var check = event.target.value
    setPasswordValid(false);
    setPassword(check);
    if (check.length < 8) return
    if(/\d/.test(check)===false) return
    if (check.toUpperCase() === check) return
    if (check.toLowerCase() === check) return
    setPasswordValid(true);
  }
  function passwordChange2(event) {
    var check = event.target.value
    setPassword2Valid(false);
    if (password === check) {
      setPassword2Valid(true);
    }
  }

  return (
    <Row className="text-center" style={{marginTop:"90px"}}>
      <Meta title="PUCHO"/>
        <Card>
          <Card.Body>
           <Card.Title className="pb-3 pt-5"><h1>Reset Password</h1></Card.Title>

           <Form className="pt-5" noValidate onSubmit={handleSubmit}>

              <Form.Group className="mb-3 pt-3" controlId="formBasicPassword">
                <Form.Label className="fw-bold">Password</Form.Label>
                <Form.Control required name="password" type="password" placeholder="Password" isInvalid={!passwordValid} isValid={passwordValid} onChange={passwordChange1} />
                <br />
                <Form.Control required type="password" placeholder="Reenter Password" isInvalid={!password2Valid} isValid={password2Valid} onChange={passwordChange2} />
                <Form.Text className="text-muted">
                  8-character minimum with a capital letter and number.
                </Form.Text>
              </Form.Group>
              <Container className="pt-2">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Container>
            </Form>
              </Card.Body>
              <Container className="pt-5 pb-5" />
          </Card>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Pucho</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  <p>Your password has been changed. Use this password when logging in.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
    </Row>
  )
}

export default Reset
