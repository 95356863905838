// router
import { BrowserRouter as Router} from 'react-router-dom'

// components
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import {Provider as ListingProvider} from '../context/listingContext';
import {Provider as AuthProvider} from '../context/authContext';

const Layout = ({ children }) => {
  return (
    <Router>
      <AuthProvider>
        <ListingProvider>
          <Menu />
          <main>{children}</main>
          <Footer />
        </ListingProvider>
      </AuthProvider>
    </Router>
  )
}

export default Layout
