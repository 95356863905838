import axios from 'axios';
import Cookies from 'universal-cookie';

const instance = axios.create({
  baseURL: 'https://pucho-server.herokuapp.com/',
  //baseURL: 'http://localhost:3000/',
  //baseURL: 'http://10.0.0.159:3000/',
});
const cookies = new Cookies();
instance.interceptors.request.use(
  async (config) => {
    const token  = await cookies.get('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default instance;
