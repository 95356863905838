import Meta from '../components/Meta'
import { VendorCard, IconCards } from '../components/Card'
import FeaturedCard from '../components/featuredCard'
import { SearchCard, SearchBar } from '../components/Search'

import { Row, Container, Col, Card, FormControl, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap'
import {Context as ListingContext} from '../context/listingContext';
import { useContext, useEffect, useState } from 'react';
import './Home.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isDesktop, isMobile} from "react-device-detect";

const Home = () => {
  // page content
  const pageTitle = 'Your central hub for all things South Asian'
  const pageDescription = 'Search hundreds of local businesses'
  const {get_listing, get_premium} = useContext(ListingContext);
  const [featured, setFeatured] = useState([]);

  useEffect(async() => {
      const response = await get_premium();
      setFeatured(response);
      console.log(response);
    }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div style={{
      backgroundImage: `url("/lang-fade.png")`, backgroundSize: "contain", marginTop: "90px"
    }}>
      <Meta title="PUCHO - Your South Asian Hub"/>
      {/* <Card className="text-black text-center" border="light">
        <Card.Img src="/lang-fade.png" alt="Card image" />
        <Card.ImgOverlay>
        <Textfit mode="single">PUCHO</Textfit>
        <Textfit>Your central hub for all things South Asian.</Textfit>
        </Card.ImgOverlay>
      </Card>
        <div>
          <img src = "/lang-fade.png" alt = "Freedom Blog" fluid width="100%" />
          <Textfit>Your central hub for all things South Asian.</Textfit>
        </div>*/}

        <Container>
          <Card className="text-black pt-2" border="light">
            <Card.Img src="/searchbanner-fade.png" style={{width:"100%", height: "200px", objectFit: "cover" }} alt="Card image" />
            <Card.ImgOverlay className="d-flex">
              { isDesktop &&
                <SearchCard   />
              }
              { isMobile &&
                <>
                  <div style={{textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Row><h5><div style={{fontWeight: "bold"}}>Pucho,</div> What are you searching for?</h5></Row>

                  </div>
                </>
              }
            </Card.ImgOverlay>
          </Card>
          <Container className = "text-black pt-2 text-center d-none d-xl-block">
            <h4>Your South Asian hub. Helping you connect to great local businesses.</h4>
          </Container>
          { isMobile &&
            <SearchBar mobile />
          }
        </Container>


        <h2 className="pt-5 pb-3 text-center">Pucho's Company Spotlight</h2>
        <Container className="text-center">
          <Carousel
            responsive={responsive}

            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderDotsOutside={false}
            >

            {featured.map((l, i) => (
              <FeaturedCard key={i} img={l.logo} name={l.name} url={l._id} type={l.type} />
            ))}
          </Carousel>
        </Container>
        <h2 className="text-center pt-5">Browse Businesses by Category</h2>

        <IconCards />

      <Container className="pt-5 pb-5 text-center">
        Planning a South Asian wedding? Pucho can help link you with local vendors!
        <Row xs={1} md={3} className="g-4 pt-3">
          <Col><VendorCard img="/photographer.jpg" category="Photographer" name="Photographers" text="Photographers" url="photographer"/> </Col>
          <Col><VendorCard img="/videographer.jpg" category="Videographer" name="Videographers" text="Videographers" url="videographer" /> </Col>
          <Col><VendorCard img="/venue.jpg" category="Banquet Hall" name="Venues" text="Wedding Venues" url="venues"/> </Col>
        </Row>
        <br></br>
        <Row xs={1} md={3} className="g-4">
          <Col><VendorCard img="/caterer.jpg" category="Restaurant" name="Caterers" text=" Caterers" /> </Col>
          <Col><VendorCard img="/mua.jpg" category="Make Up Artist (MUA)" name="MakeupArtists" text="Makeup Artists" /> </Col>
          <Col><VendorCard img="/decor.jpg" category="Wedding Décor" name="Decor" text="Wedding Decor" /> </Col>
        </Row>
      </Container>

      <Card className="bg-dark text-white text-center">
        <Card.Img src="/splash.png" alt="Card image" />
        <Card.ImgOverlay>
          <Card.Title className="d-none d-md-block pt-5"><h3>Looking to take your business to the next level?</h3></Card.Title>
          <Card.Text>List with us today!</Card.Text>
          <Button href="/about" variant="outline-light">Find out more</Button>
        </Card.ImgOverlay>
      </Card>
      <br></br>
    </div>
  )
}

export default Home
