import { Col, Form, Button, FormControl, Container, Row, Card, ListGroup } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa';
import { useContext, useEffect, useState, useRef } from 'react';
import   { useHistory } from "react-router-dom";
import { Context as AuthContext } from '../context/authContext';
import {Context as ListingContext} from '../context/listingContext';

export const SearchCard = (props) => {
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const history = useHistory();
  const [showCategories, setShowCategories] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [cityQuery, setCityQuery] = useState("");
  const [categoryQuery, setCategoryQuery] = useState("");
  const searchRef = useRef(null);
  const { get_categories, get_cities } = useContext(AuthContext);
  useEffect( async() => {
      setCategories(await get_categories());
      setCities(await get_cities());
      function handler(event) {
          // change starts here
          if(!searchRef.current?.contains(event.target)) {
              setShowCategories(false);
              setShowCities(false);
          } else {
            setShowCategories(true);
            setShowCities(true);
          }
          // change starts here
      }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)

    }, []);

    const filteredCategories = categories.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(categoryQuery.toLowerCase()) > -1
    );
    const filteredCities = cities.filter(
      suggestion =>
        suggestion.city.toLowerCase().indexOf(cityQuery.toLowerCase()) > -1
    );

    function navigateCategory() {
      var category = categoryQuery;
      var city = cityQuery;
      if (categoryQuery ==="") {category="all"};
      if (cityQuery ==="") {city="all"};
      history.push("/explore/" + category + "/" + city);
    }

    function handleCategory(result) {
      setCategoryQuery(result)
      setShowCategories(false);
    }

    function handleCity(result) {
      setCityQuery(result)
      setShowCities(false);
    }

    function handleCategorySearch(result) {
      setCategoryQuery(result);
      setShowCategories(true);
    }

    function handleCitySearch(result) {
      setCityQuery(result);
      setShowCities(true);
    }


  return (
    <Container style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
    <div>
      <Row className="text-center"><h5><div style={{fontWeight: "bold"}}>Pucho,</div> What are you searching for?</h5></Row>
      <Row>
        <Col>
          <Form className="d-flex">
            <FormControl
              type="search"
              placeholder="What?"
              className="ms-3"
              value={categoryQuery}
              aria-label="Where"
              onChange={(e)=>handleCategorySearch(e.target.value)}
            />
            { showCategories &&
              <Card style={{position: "absolute", textAlign: "left", marginTop:"40px", marginLeft: "16px"}}>
                { categoryQuery !== "" &&
                  <Card.Body>
                          <ListGroup variant="flush">
                            { filteredCategories.slice(0, 5).map((l,i) => (
                              <ListGroup.Item key={i}><Button variant="light" size="sm" onClick={()=>handleCategory(l)}>{l}</Button></ListGroup.Item>
                            ))
                            }
                          </ListGroup>
                  </Card.Body>
                }
              </Card>
            }
            <FormControl
              type="search"
              placeholder="Near"
              className="ms-3"
              value={cityQuery}
              aria-label="Where"
              onChange={(e)=>handleCitySearch(e.target.value)}
            />
            { showCities &&
              <Card style={{position: "absolute", textAlign: "left", marginTop:"40px", marginLeft: "357px"}}>
                { cityQuery !== "" &&
                  <Card.Body>
                          <ListGroup variant="flush">
                            { filteredCities.slice(0, 5).map((l,i) => (
                              <ListGroup.Item key={i}><Button variant="light" size="sm" onClick={()=>handleCity(l.city)}>{l.city}</Button></ListGroup.Item>
                            ))
                            }
                          </ListGroup>
                  </Card.Body>
                }
              </Card>
            }
            <Button variant="outline-dark" onClick={navigateCategory}><FaSearch /></Button>
          </Form>
        </Col>
      </Row>
      </div>
      </Container>
  )
}

export default SearchCard

export const SearchBar = (props) => {
  const {  get_categories, get_cities } = useContext(AuthContext);
  const {  get_filtered } = useContext(ListingContext);
  const [categories, setCategories] = useState([]);
  const [names, setNames] = useState([]);
  const [query, setQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [data, setData] = useState();
  const history = useHistory({forceRefresh: true});
  const searchRef = useRef(null)

  useEffect( async() => {
        setCategories(await get_categories());

        const response = await get_filtered( "names", "");
        const cities = await get_cities();
        setNames(response);
        setData(response);
        //setData(names);

      function handler(event) {
          // change starts here
          if(!searchRef.current?.contains(event.target)) {
              setShowSearch(false);
          } else { setShowSearch(true) }
          // change starts here
      }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)

    }, []);
  function navigateListing(result) {
    const url = data.filter(item => item.name === result);
    history.push("/listing/"+ url[0]._id+"/" + url[0].name);
    setShowSearch(false);
    setQuery("");
  }
  function navigateCategory(result) {
    history.push("/explore/" + result + "/all");
    setShowSearch(false);
    setQuery("");
  }

  function handleSearch(query) {
    setQuery(query);
    setShowSearch(true);
  }
  const filteredCategories = categories.filter(
    suggestion =>
      suggestion.toLowerCase().indexOf(query.toLowerCase()) > -1
  );
  const filteredNames = names.filter(
    suggestion =>
      suggestion.name.toLowerCase().indexOf(query.toLowerCase()) > -1
  );

    return (
      <Form className="" style={{}}>
        <div style={{backgroundColor: "white", borderStyle: "solid", borderWidth: "1px", height: "40px"}}>
          <Form.Group className="mb-3" controlId="formBasicEmail" ref={searchRef}>
            <FaSearch style={{marginLeft: "1rem", position: "absolute", marginTop:"0.45rem"}} size="1.25em" />
            <Form.Control autoComplete="off" style={{ boxShadow: "0px 0px", marginLeft: "45px", width: "80%", border: "none"}} type="search" placeholder={"Search"} onChange={(e)=>handleSearch(e.target.value)} value={query}/>
            { showSearch &&
              <Card style={{position: "absolute", textAlign: "center", zIndex:"99", fontSize:"13px"}}>
                { query !== "" &&
                  <Card.Body>
                    <Row>
                      <Col md="auto">
                          <div style={{fontWeight: "bold"}}>Listings</div>
                          <ListGroup variant="flush">
                            { filteredNames.slice(0, 4).map((l,i) => (
                              <ListGroup.Item key={i}><Button style={{fontSize: "12px"}} variant="light" size="sm" onClick={()=>navigateListing(l.name)}>{l.name}</Button></ListGroup.Item>
                            ))
                            }
                          {props.mobile && <br />}
                          </ListGroup>
                      </Col>
                      <Col>
                      <div style={{fontWeight: "bold"}}>Categories</div>
                      <ListGroup variant="flush">
                        { filteredCategories.slice(0, 4).map((l,i) => (
                          <ListGroup.Item key={i}><Button style={{fontSize: "12px"}} variant="light" size="sm" onClick={()=>navigateCategory(l)}>{l}</Button></ListGroup.Item>
                        ))
                        }
                      </ListGroup>
                      </Col>
                    </Row>
                  </Card.Body>
                }
              </Card>
            }
          </Form.Group>
      </div>
    </Form>
  )
}
