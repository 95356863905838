import { Container } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'

// Layout
import Layout from './layout/Layout'

// pages
import Home from './pages/Home'
import Listing from './pages/Listing'
import Signin from './pages/Signin'
import Signup from './pages/Signup'
import Forgot from './pages/ForgotPassword'
import Reset from './pages/ResetPassword'
import AddListing from './pages/AddListing'
import MyAccount from './pages/MyAccount'
import Verify from './pages/Verify'
import Categories from './pages/Categories'
import Terms from './pages/Terms'
import Contact from './pages/Contact'
import Help from './pages/Help'
import Privacy from './pages/Privacy'
import ContentGuidelines from './pages/ContentGuidelines'

import About from './pages/About'
import NotFound from './pages/NotFound'

const App = () => {
  return (
    <Layout>
      <Container>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/about' component={About} />
          <Route path='/termsofservice' component={Terms} />
          <Route path='/contentguidelines' component={ContentGuidelines} />
          <Route path='/help' component={Help} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/contact' component={Contact} />
          <Route path='/login' component={Signin} />
          <Route path='/signup' component={Signup} />
          <Route path='/myaccount' component={MyAccount} />
          <Route path='/forgotpassword' component={Forgot} />
          <Route path='/verify' component={Verify} />
          <Route path="/reset/:link" children={<Reset />} />
          <Route path='/addlisting' component={AddListing} />
          <Route path='/explore/:category/:city' children={<Categories />} />
          <Route path="/listing/:id/:name" children={<Listing />} />
          <Route component={NotFound} />
        </Switch>
      </Container>
    </Layout>
  )
}

export default App
