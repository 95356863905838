import { Row, Col, Container, Form, Button, Badge } from 'react-bootstrap'
import { useState } from 'react';
import { FaPlus, FaTimesCircle } from 'react-icons/fa';

const Services = ({services, setServices}) => {
  const [addService, setAddService] = useState('');

  function add_list() {
    if (addService === "") {
      return
    }
    setServices([...services, addService]);
    setAddService("");
  }
  function remove_item(service) {
    console.log(service);
    setServices(services.filter(function(item) {
      return item !== service
    }))
  }
  return (
    <div>
      <Row className="pb-3">
       <Form.Group as={Col} md="4" controlId="services">
         <Form.Label>Add a Service</Form.Label>
         <Form.Control
           type="text"
           placeholder=""
           value={addService}
           onChange={e => setAddService(e.target.value)} />
         <Form.Control.Feedback type="invalid">
           Please provide a valid link.
         </Form.Control.Feedback>
       </Form.Group>
       <Button style={{width: "60px", height: "40px", marginTop: "31px"}} onClick={add_list}><FaPlus /></Button>
     </Row>
     <Row>
      <Col style={{display:"flex"}}>
       {
         services.map((l,i) => (
           <div key={i}>
             <Badge pill bg="dark">{l}&ensp;<Button variant="dark" onClick={()=>remove_item(l)}><FaTimesCircle size={15} /></Button></Badge>
             &ensp;
          </div>
         ))
       }
      </Col>
     </Row>
    </div>
  )
}

export default Services
