import Meta from '../components/Meta'
import { Table, Container, Col, Card, FormControl, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap'

const Help = () => {

  return (
    <Container style={{marginTop: "90px"}}>
      <Meta title={"Pucho - Help"}/>
      <h3>Help</h3>
      For any technical issues, contact support@pucho.ca.
      <hr />
      <h3>FAQs</h3>
      <hr />

    </Container>
  )
}

export default Help
