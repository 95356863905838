import React, {useContext, useState} from 'react';
import Cookies from 'universal-cookie';
import createDataContext from './createDataContext';
import puchoAPI from '../api/puchoBackend';
import cloudinaryAPI from '../api/cloudinary';

const listingReducer = (state, action) => {
  switch (action.type) {
    case 'add_listing':
      return { ...state, status: action.payload };
    case 'get_listings':
      return { ...state, data: action.payload };
    case 'get_listing':
      return { ...state, listing: action.payload };
    case 'add_error':
      return { ...state, errorMessage: action.payload };
    case 'send_email':
        return { ...state, errorMessage: action.payload };
    case 'clear_error_message':
      return {...state, errorMessage: ''};
    case 'clear_listing':
      return {listing: null};
    case 'get_batch':
      return {...state, status: action.payload};
    default:
      return state;
  }
};

const get_listings = (dispatch) => {
  return async() => {
    try {
      const response = await puchoAPI.get('/mylistings/');
      await dispatch({ type: 'get_listings', payload: response.data});
      console.log(response.data);
      return response.data;
    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return;
    }
  };
};

const get_listing = (dispatch) => {
  return async({_id}) => {
    try {
      const response = await puchoAPI.get('/listings/' + _id);
      dispatch({ type: 'clear_listing'});
      dispatch({ type: 'get_listing', payload: response.data});
      console.log(response.data);
      return response.data;
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return {validated: false, userID: "", error:"404"}
    }
  };
};

const get_filtered = (dispatch) => {
  return async( category, city) => {
    try {
      var query = ""
      if (category === "All" || category ==="all") {
        if (city ==="All" | city==="all") {
          query = {filter: "approved"};
        } else {
          query = {filter: "location", city: city}
        }
      } else {
        if (city ==="All" | city==="all") {
          query = {filter: "category", category: category};
        } else {
          query = {filter: "category-location", category: category, city: city}
        }
      }

      if (category === "names") {
        query = {filter: "names"};
      }

      const response = await puchoAPI.post('/listings/', query);
      return response.data;
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return {}
    }
  };
};

const get_premium = (dispatch) => {
  return async( city) => {
    try {
      const response = await puchoAPI.post('/listings/', {filter: "approved"});
      return response.data;
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return {}
    }
  };
};

const delete_listing = () => {
  return async({_id}) => {
    try {
      const response = await puchoAPI.delete('/mylistings/' + _id);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };
};

const add_listing = (dispatch) => {
  return async (
    listing) => {
    //make API request to sign up
    try {
      const response = await puchoAPI.post('/mylistings', listing);
      dispatch({ type: 'add_listing', payload: response.data});
      console.log(response.data);
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
    }
  };
};

const update_listing = (dispatch) => {
  return async (
    listing, _id) => {
    //make API request to sign up
    try {
      const response = await puchoAPI.patch('/mylistings/' + _id, listing);
      console.log(response.data);
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
    }
  };
};

const send_email = (dispatch) => {
  return async (
    message) => {
    try {
      const response = await puchoAPI.post('/send/', message);
      console.log(response.data);
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
    }
  };
};

//make API request to get Cloudinary signature from API
const get_sig = () => {
  return async (
    eager,
    name,
  ) => {
    try {
      const timestamp = Math.round(new Date() / 1000);
      const response = await puchoAPI.post('/cloudinary', {
        eager, name, timestamp,
        });
      return (response.data);
    } catch (err) {
      return (err);
    }
  };
};

//upload image to Cloudinary
const cloudinaryUpload = () => {
  return async (
    photo,
    sig,
    timestamp,
  ) => {
    const data = new FormData();
    const apiKey = '742954921391394';
    data.append('file', photo);
    data.append('timestamp', timestamp);
    data.append('api_key', apiKey);
    data.append('signature', sig);
    try {
      const response = await cloudinaryAPI.post('https://api.cloudinary.com/v1_1/www-pucho-ca/image/upload',
        data);
      return (response.data);
    } catch (err) {
      return (err);
    }
    };
  };

  //make API request to delete image from Cloudinary server
  const cloudinaryDelete = () => {
    return async (
      name,
    ) => {
      try {
        const timestamp = Math.round(new Date() / 1000);
        const response = await puchoAPI.post('/cloudinary/delete/', {
          name, timestamp,
          });
        return (response.data);
      } catch (err) {
        return (err);
      }
    };
  };

const anAsyncFunction = async item => {
  try {
    const response = await puchoAPI.get('/listings/' + item);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const get_batch = () => {
  return async({reqArray}) => {
    try {
      return Promise.all(reqArray.map((item,i) => anAsyncFunction(item)));
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };
};

const clearErrorMessage = dispatch => () => {
  dispatch({ type: 'clear_error_message'});
};

const clearListing = dispatch => () => {
  dispatch({ type: 'clear_listing'});
};

export const { Provider, Context } = createDataContext(
  listingReducer,
  { add_listing,
    update_listing,
    clearErrorMessage,
    get_listings,
    get_listing,
    clearListing,
    get_batch,
    get_sig,
    cloudinaryUpload,
    cloudinaryDelete,
    delete_listing,
    send_email,
    get_filtered,
    get_premium},
  { data:null, errorMessage: '' }
);
