import { Carousel } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import './Carousel.css'
import {
    FaArrowCircleRight,
    FaArrowCircleLeft
  } from 'react-icons/fa'

export const Slideshow = (props) => {
  return (
    <div className="CarouselImg text-center">
      <Carousel
        varient="dark"
        nextIcon=<FaArrowCircleRight size={50}/>
        prevIcon=<FaArrowCircleLeft size={50}/>
        nextLabel = ""
        prevLabel = ""
      >
        {props.imgArray.map((image) => (
          <Carousel.Item>
            <Image
              key={image.toString()}
              src={image}
              rounded
            />
          </Carousel.Item>
          ))}
      </Carousel>
    </div>
  )
}

export const CardGallery = (props) => {
  return (
    <div className="text-center" style={{height: "200px", width: "275px"}}>
      <Carousel
        varient="dark"
        nextIcon=<FaArrowCircleRight size={50}/>
        prevIcon=<FaArrowCircleLeft size={50}/>
        nextLabel = ""
        prevLabel = ""
        interval={null}
      >
        {props.imgArray.map((image) => (
          <Carousel.Item key={image}>
            <Image
              key={image.toString()}
              src={image}
              rounded
              style={{height: "200px", width: "275px", maxWidth: "275px", maxHeight: "200px", objectFit: "cover"}}
            />
          </Carousel.Item>
          ))}
      </Carousel>
    </div>
  )
}
