import { React, Component } from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { Container } from 'react-bootstrap'

export class MapContainer extends Component {
  render() {
    const style = {
      height: 250,
      width: '100%',
      alignItems: 'left',
      position: 'relative',
      paddingLeft: 0
    }
    return (
      <Container style={style}>
        <Map
          google={this.props.google}
          zoom={15}
          initialCenter={{
              lat: this.props.lat,
              lng: this.props.lng
            }}
        >

          <Marker onClick={this.onMarkerClick}
                  name={'Current location'} />

          <InfoWindow onClose={this.onInfoWindowClose}>
              <div>
                <h1>Test</h1>
              </div>
          </InfoWindow>
        </Map>
      </Container>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyB7JqenDvjDqDoew8wcuk1Kb6CIaac_z28")
})(MapContainer)
