import Meta from '../components/Meta'
import { Slideshow, CardGallery } from '../components/Carousel'
import MapContainer from '../components/Map'
import Hours from '../components/Hours'
import { Social, ContactCard } from '../components/Social'
import {
  isDesktop,
  isMobile
} from "react-device-detect";

import { Row, Container, Col, Image, Card, Form, Button, Badge } from 'react-bootstrap'
import './Listing.css'
import { useContext, useState, useEffect } from 'react';
import {Context as ListingContext} from '../context/listingContext';
import {Context as AuthContext} from '../context/authContext';
import   { useParams, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const Listing = () => {
  const {
    state,
    send_email,
    clearListing,
    get_listing
  } = useContext(ListingContext);

  const { state: auth, authCheck, recaptcha } = useContext(AuthContext);
  const history = useHistory();
  // page content
  let { id, name } = useParams();
  useEffect(async() => {
      await clearListing();
      const response = await get_listing({_id: id});
      if (response.error === "404") {
          history.push("/404")
      }
      if (response.approved === false) {
        const id = await authCheck();
        if (id !== response.userID) {
          history.push("/404")
        }
      }
    }, [id, name]);
    const [validated, setValidated] = useState(false);
    const [robotCheck, setRobotCheck] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (form.checkValidity()===true) {
        send_email({ID: "a87kjn3a8lci", to:state.listing.email, email:form.email.value, name: form.name.value, body: form.body.value});
        alert("Your message has been sent! You will receive a reply in the inbox of your provided email address.")
      }
      setValidated(true);
    };
    if (typeof state?.listing?.photoURL == "undefined" ) {
      return <></>;
    }
    async function onChange(value) {
      console.log("Captcha value:", value);
      const response = await recaptcha(value);

      if (response.success === true) {
        setRobotCheck(true);
      }
    }
      return (
    <div className="Body">
      <Container>
        <Meta title={"PUCHO - " + state.listing.name} />
        <Row>
          <Col xs={12} md={8}>
            <Row>
              <Slideshow imgArray={state.listing.photoURL} />
              <div className = "Logo pt-3">
                { state.listing.logo && <Image src={state.listing.logo} style={{width: isMobile ? "75px": "100px", height: isMobile ? "75px" : "100px"}} rounded /> }
                <div className="Title">
                  { isMobile ? <>
                    <div style={{fontSize: "16px"}}>{state.listing.name}</div>
                    <div style={{fontSize: "14px", fontWeight: "bold"}}> {state.listing.type}</div>
                    </>
                    :<>
                     <h1>{state.listing.name}</h1>
                     <strong>{state.listing.type}</strong>
                     </>
                  }
                </div>
                { isDesktop ?
                  <div className="Share pt-2">
                    { state.listing.social ? <Social links={state.listing.social} share small={isMobile} />: <></> }
                  </div> : <></>
                }
              </div>
            </Row>
            { isMobile?
              <>
              <Row>
              <div className="Share pt-2">
                { state.listing.social ? <Social links={state.listing.social} />: <></> }
              </div>
              </Row>
              <hr />
              <ContactCard listing={state.listing} />
            </> : <></>

            }

            <hr />
            <div className="pt-3">
              <h4><strong>About Us</strong></h4>
              {/*<p style={{whiteSpace:"pre-line"}}>{state.listing.info}</p>*/}
              <div style={{whiteSpace:"pre-line"}}
                dangerouslySetInnerHTML={{
                  __html: state.listing.info
                }}></div>
            </div>

            <hr />
            { state.listing.keywords.length > 0 &&
              <div className="pt-3">
                <h4><strong>Services</strong></h4>
                  { state.listing.keywords.map((l, i) => (
                      <><Badge key={i} pill bg="dark">{l}</Badge> </>
                  ))}
                  <hr />
              </div>
            }

            { state.listing.mapCords.lat ?
              <>
                <div className="pt-3">
                  <h4><strong>Location</strong></h4>
                  {/*}<MapContainer lat =  {state.listing.mapCords.lat} lng = {state.listing.mapCords.lng}/> */}
                  <a href={"https://www.google.com/maps/dir//" + state.listing.name + "," + state.listing.address + "/"} target="_blank" rel="noopener noreferrer">
                    <img
                      src={"https://maps.googleapis.com/maps/api/staticmap?addresses=" + state.listing.address + "&style=feature:poi|visibility:off&zoom=15&scale=1&size=1000x300&maptype=roadmap&key=AIzaSyB7JqenDvjDqDoew8wcuk1Kb6CIaac_z28&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C" + state.listing.mapCords.lat + "," + state.listing.mapCords.lng} alt={"Google Map of "+ state.listing.name}
                      style={{width: "100%", objectFit: "contain"}}
                     />
                    </a>
                </div>
                <hr />
              </> : <></>
            }
            { state.listing.hours ?
              <Hours hours={state.listing.hours}/>
             : <></>
           }

          </Col>
          <Col xs={6} md={4} className="d-none d-md-block">
            <div>
              <div>
                <Card bg="light" className="pt-2">
                  <Card.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      Contact {state.listing.name} today!
                      <Form.Group className= "pt-2" controlId="contactForm.email">
                        <Form.Control name="email" type="email" placeholder="Your Email" required />
                      </Form.Group>
                      <Form.Group className="pt-2" controlId="contactForm.name">
                        <Form.Control name="name" type="text" placeholder="Your Name" required />
                      </Form.Group>
                      <Form.Group className="pt-4" controlId="contactForm.body">
                        <Form.Control name="body" as="textarea" rows={5} placeholder="Message" required />
                      </Form.Group>
                      <div className="pt-2 text-center" >
                        <Button type="submit" variant="outline-dark" disabled={!robotCheck}>Send</Button>
                        <Container className="pt-2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <ReCAPTCHA
                            sitekey="6LcQOKAcAAAAAIG9Wel7t5smUJIC0uX2fw9PAtQ-"
                            onChange={onChange}
                          />
                        </Container>
                    </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>


              <div className = "pt-3">
                <ContactCard listing={state.listing} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Listing
