import Meta from '../components/Meta'
import { Social } from '../components/Social'
import { CardGallery } from '../components/Carousel'
import Filter from "../components/Filter"
import './Categories.css';
import { FaTimesCircle } from 'react-icons/fa'

import ReactPaginate from 'react-paginate';
import {
  isDesktop,
  isMobile
} from "react-device-detect";

import { Row, Col, Nav, ListGroup, Image, Spinner, Button, Navbar, Container } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Context as AuthContext } from '../context/authContext';
import {Context as ListingContext} from '../context/listingContext';
import { useContext, useEffect, useState } from 'react';
import   { useParams, useHistory } from "react-router-dom";
import './Home.css'
import 'react-multi-carousel/lib/styles.css';

const Categories = () => {
  // page content
  let { category, city } = useParams();
  const history = useHistory({forceRefresh:true});
  const {get_filtered } = useContext(ListingContext);
  const { get_categories, get_cities } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [listings, setListings] = useState([]);
  const [cities, setCities] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [pageCount, setPageCount] = useState();
  const [perPage, setPerPage] = useState(5);
  const [data, setData] = useState();
  const [pageData, setPageData] = useState([]);
  const [total, setTotal] = useState();
  const [drawer, setDrawer] = useState(false);

  function handleClick(link) {
    history.push("/listing/"+link.url+"/" + link.name.replace(/\s+/g, '-').toLowerCase());
  }

  useEffect(async() => {
      const load = await get_categories();
      await setCategories(load);
      const load2 = await get_cities();
      await setCities(load2);

      const response = await get_filtered( category, city);
      setListings(response);
      setPageData(response);
      setTotal(Object.keys(response).length);
      setPageCount(Math.ceil(response.length / perPage));
      setData(Object.values(response));
      renderList(response);
    }, [category, city]);

  function handlePageClick(data) {
      setLoaded(false);
      console.log(data.selected)
      let selected = data.selected;
      const offset = Math.ceil(selected * perPage);

      var upperLimit
      if ((offset+perPage) >= total) {
        upperLimit = total
      } else {
        upperLimit = offset + perPage;
      }
      console.log(offset)
      var tempArray = [];
      for (let i = offset; i < upperLimit; i++) {
        tempArray.push(listings[i]);
      }
      setLoaded(true);
      setPageData(tempArray);
  };

  function renderList(curData) {
    console.log(curData)
    const offset = 0;

    var upperLimit
    if ((offset+perPage) > curData.length) {
      upperLimit = curData.length;
    } else {
      upperLimit = offset + perPage;
    }

    var tempArray = [];
    for (let i = offset; i < upperLimit; i++) {
      tempArray.push(curData[i]);
    }
    setLoaded(true);
    setPageData(tempArray);
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div style={{marginTop: "90px"}}>
      <Meta title="PUCHO - Your South Asian Hub"/>
      <Row className="pt-3">

        { isDesktop &&
          <Filter cities={cities} category={category} city={city} mobile={false} setShow={setDrawer} />
        }

        { isMobile &&
          <>
            <Offcanvas show={drawer} onHide={()=>setDrawer(!drawer)} scroll={true}>
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Filter cities={cities} category={category} city={city} mobile={true} setShow={setDrawer}/>
            </Offcanvas>
          </>
        }

        <Col sm={9}>
        { (loaded === false) ?
          <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
          </Spinner>
        :   <>{total} Total Results <br />
            <ListGroup className="react-paginate" variant="flush">
            { pageData.map((l,i) => (
              <ListGroup.Item className="pt-3 pb-3 pl-3 pr-3" key={i}>
                { isDesktop ?
                  <Row>
                    <Col md="auto">
                      { l.photoURL.length !== 0 && <CardGallery imgArray={l.photoURL} /> }
                      <div style={{width: "275px", height: "75px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Social links={l.social} share />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col md="auto">
                          <div
                            style={{cursor: "pointer", height: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "container", float:"left", padding: "5px"}}
                            onClick={() => handleClick({name: l.name, url: l._id})}>
                            { l.logo ? <Image src={l.logo} rounded fluid /> : <></> }
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="pt-3" onClick={() => handleClick({name: l.name, url: l._id})} style={{cursor: "pointer"}}>
                            <h4>{l.name}</h4>
                            <p style={{fontWeight: "bold"}}>{l.type}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="text pt-2">{l.info}</div>
                      </Row>
                    </Col>
                  </Row>
                  :
                  <>
                    <Row onClick={() => handleClick({name: l.name, url: l._id})}>
                      <Col style={{height: "90px", paddingRight: "0px"}} xs="4">
                        { l.logo && <Image src={l.logo} rounded fluid style={{borderStyle: "solid", borderWidth: "1px", height:"75px", width: "90px", objectFit: "contain"}} /> }
                      </Col>
                      <Col style={{paddingLeft:"0px"}}>
                      {l.name}
                      <div style={{fontWeight: "bold", fontSize: "12px"}}>{l.type}</div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mobile-text">{l.info}</div>
                    </Row>
                  </>
              }
              </ListGroup.Item>
              ))}
              <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}

                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              /></div>
              {isMobile && <div style={{marginBottom: "20px"}} />}
            </ListGroup></>
            }
        </Col>
      </Row>

      { isMobile &&
        <Navbar bg="light" fixed="bottom">
          <Container fluid>
            <div className="text-center">
              <Button variant="outline-dark" onClick={()=>setDrawer(!drawer)}>
                Filters
              </Button>
            </div>
            <div style={{fontSize: "15px"}}>
              {category === "all" ? "" :
                <Row>
                  <Col md="7">{category + '  '}
                    <a href={"/explore/all/"+city} style={{color: "red"}}><FaTimesCircle/></a></Col>
                </Row>
              }
              {city === "all" ? "" :
                <Row>
                  <Col md="7">{city + '  '}
                    <a href={"/explore/"+category+"/all"} style={{color: "red"}}><FaTimesCircle/></a></Col>
                </Row>
              }
            </div>
          </Container>
        </Navbar>
      }
    </div>
  )
}

export default Categories
