import createDataContext from './createDataContext';
import puchoAPI from '../api/puchoBackend';
import Cookies from 'universal-cookie';
import axios from 'axios';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'add_error':
      return { ...state, errorMessage: action.payload };
    case 'signin':
      return { ...state, token: action.payload };
    case 'clear_error_message':
      return {...state, errorMessage: ''};
    case 'signout':
      return { token: null, errorMessage: '', verified: {_id:""}};
    case 'update_favs':
      return { ...state, favs: action.payload };
    case 'is_verified':
      return { ...state, verified: action.payload };
    case 'set_categories':
      return { ...state, categories: action.payload };
    case 'server_flag':
        return { ...state, serverResponse: action.payload };
    default:
      return state;
  }
};

const tryLocalSignin = dispatch => async () => {
  const cookies = new Cookies();

  const token = await cookies.get('token');
  const verified = await cookies.get('verified');
  const email = await cookies.get('email');
  const id = await cookies.get('id');
  const method = await cookies.get('method');
  //const favs = await cookies.get('favs').then(req => JSON.parse(req));

  if (token) {
    console.log(token);
    await dispatch({ type: 'signin', payload: token});
    //const response = await puchoAPI.get('/mylistings/fav/get');
    //console.log(response.data);
    //dispatch({ type: 'update_favs', payload: response.data });
  } else {
    //dispatch({ type: 'update_favs', payload: favs });
  }
  if (verified === 'true') {
    dispatch({ type: 'is_verified', payload: {status: true, id: email, _id: id}});
  } else {
    dispatch({ type: 'is_verified', payload: {status: false, id: email, _id: id}});
  }
};
const authCheck = dispatch => async () => {
  const cookies = new Cookies();
  const token = await cookies.get('token');
  const _id = await cookies.get('id');
  if (token) {
    return _id;
  } else {
    return "";
  }
};
const get_categories = (dispatch) => {
  return async() => {
    try {
      const cookies = new Cookies();
      if (cookies.get('categories')) {
        return cookies.get('categories');
      } else {
        const categories = await puchoAPI.get('/categories');
        await dispatch({ type: 'set_categories', payload: categories.data});
        cookies.set('categories', categories.data, { path: '/' });
        return categories.data;
        //console.log(response.data);
      }

    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return ["Select"];
    }
  };
};
const get_cities = (dispatch) => {
  return async() => {
    try {
      const cookies = new Cookies();
      if (cookies.get('cities')) {
        return cookies.get('cities');
      } else {
        const cities = await puchoAPI.get('/cities');
        await dispatch({ type: 'cities', payload: cities.data});
        cookies.set('cities', cities.data, { path: '/' });
        return cities.data;
        //console.log(response.data);
      }

    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return ["Select"];
    }
  };
};
const clearErrorMessage = dispatch => () => {
  dispatch({ type: 'clear_error_message'});
  dispatch({ type: 'server_flag', payload: false})
};

const signup = dispatch => {
  return async ({email, password}) => {
    //make API request to sign up
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/signup', { email, password });
      dispatch({ type: 'is_verified', payload: {status: false, id: email}});
      dispatch({ type: 'signin', payload: response.data.token});
      const cookies = new Cookies();
      cookies.set('token', response.data.token, { path: '/' });
      cookies.set('verified', JSON.stringify(response.data.verified), { path: '/' });
      cookies.set('email', email, { path: '/' });
      cookies.set('id', response.data.id, { path: '/' });
      cookies.set('method', "local", { path: '/' });
      return true;
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Email already in use!' });
      console.log(err);
      return false;
    }
  };
};

const signin = (dispatch) => {
  return async ({email, password}) => {
    //make API request to sign up
    var status = false;
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/signin', { email, password });
      if (response.data === "Google") {
        return "Google"
      }
      if (response.data.verified === true) {
        dispatch({ type: 'is_verified', payload: {status: true, id: email}});
        status = true;
      } else {
        dispatch({ type: 'is_verified', payload: {status: false, id: email}});
      }
      const cookies = new Cookies();

      cookies.set('token', response.data.token, { path: '/' });
      cookies.set('verified', JSON.stringify(response.data.verified), { path: '/' });
      cookies.set('email', email, { path: '/' });
      cookies.set('id', response.data.id, { path: '/' });
      cookies.set('method', "local", {path: '/' });
      //console.log(cookies.get('myCat')); // Pacman
      //await AsyncStorage.setItem('token', response.data.token);
      //await AsyncStorage.setItem('verified', JSON.stringify(response.data.verified));
      //await AsyncStorage.setItem('email', email);
      //await AsyncStorage.setItem('favs', JSON.stringify(response.data.bookmarks));
      //dispatch({ type: 'update_favs', payload: response.data.bookmarks});
      dispatch({ type: 'signin', payload: response.data.token});
      return {valid:true, verified: status};
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Wrong email or password provided' });
      console.log(err);
      return {valid:false, verified: false};
    }
  };
};

const signinGoogle = (dispatch) => {
  return async({token}) => {
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/googlesignin', { token });
      const cookies = new Cookies();

      cookies.set('token', response.data.token, { path: '/' });
      cookies.set('verified', JSON.stringify(response.data.verified), { path: '/' });
      cookies.set('email', response.data.email, { path: '/' });
      cookies.set('id', response.data.id, { path: '/' });
      cookies.set('method', "Google", {path: '/' });
      dispatch({ type: 'signin', payload: response.data.token});
      return response.data;
    } catch (err) {
      dispatch({ type: 'add_error', payload: 'Wrong email or password provided' });
      console.log(err);
      return {valid:false, verified: false};
    }
  }
}

const changePassword = (dispatch) => {
  return async ({email, password, newPassword}) => {
    //make API request to change password
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/changePassword', { email, password, newPassword });
      const cookies = new Cookies();
      cookies.set('token', response.data.token, { path: '/' });
      cookies.set('verified', JSON.stringify(response.data.verified), { path: '/' });
      cookies.set('email', response.data.email, { path: '/' });
      cookies.set('id', response.data.id, { path: '/' });
      dispatch({ type: 'signin', payload: response.data.token});
      return true;
    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Wrong password' });
      console.log(err);
      return false;
    }
  };
};

const resetPassword = (dispatch) => {
  return async ({token, newPassword, id}) => {
    //make API request to change password
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/resetpasswordlink', { token, newPassword, id });
      const cookies = new Cookies();
      cookies.set('token', response.data.token, { path: '/' });
      cookies.set('verified', JSON.stringify(response.data.verified), { path: '/' });
      cookies.set('email', response.data.email, { path: '/' });
      cookies.set('id', response.data.id, { path: '/' });
      dispatch({ type: 'signin', payload: response.data.token});
      return true;
    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Something went wrong' });
      console.log(err);
      return false;
    }
  };
};

const forgotPassword = (dispatch) => {
  return async ({email}) => {
    //make API request to change password
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/resetpassword', { email });
      return response;
    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Wrong password' });
      console.log(err);
      return false;
    }
  };
};

const deleteAccount = (dispatch) => {
  return async ({_id}) => {
    //make API request to change password
    try {
      clearErrorMessage();
      const response = await puchoAPI.delete("/auth/"+ _id);
      return response;
    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Wrong password' });
      console.log(err);
      return false;
    }
  };
};


const verify = (dispatch) => {
  return async ({email, code, token}) => {
    //make API request to sign up
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/code', { email, code, token });
      console.log(response.data);


      if (response.data.verified === true) {
        await dispatch({ type: 'is_verified', payload: {status: true, id: email}});
        const cookies = new Cookies();
        await cookies.set('verified', JSON.stringify(response.data.verified), {path: '/'});
        await dispatch({ type: 'server_flag', payload: true });
        return true;
      } else {
        await dispatch({ type: 'is_verified', payload: {status: false, id: email}});
        await dispatch({ type: 'add_error', payload: 'Code is wrong or expired. Please try again or request a new code.' });
        await dispatch({ type: 'server_flag', payload: true });
        return false;
      }
    } catch (err) {
      await dispatch({ type: 'add_error', payload: 'Code is wrong or expired. Please try again or request a new code.' });
      await dispatch({ type: 'server_flag', payload: true });
      console.log(err);
      return false;
    }
  };
};

const verifyReset = () => {
  return async ({email, token}) => {
    //make API request to sign up
    try {
      clearErrorMessage();
      const response = await puchoAPI.post('/auth/codeReset', { email, token });
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };
};

const recaptcha = () => {
  return async (value) => {
    //make API request to sign up
    try {
      const response = await puchoAPI.post('/recaptcha', { token: value });
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
};
const signout = (dispatch) => async () => {
  const cookies = new Cookies();

  await cookies.remove('token');
  await cookies.remove('verified');
  await cookies.remove('email');
  await cookies.remove('id');
  dispatch({ type: 'signout' });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { signin,
    signout,
    signup,
    clearErrorMessage,
    tryLocalSignin,
    verify,
    verifyReset,
    changePassword,
    get_categories,
    get_cities,
    authCheck,
    signinGoogle,
    forgotPassword,
    resetPassword,
    deleteAccount,
    recaptcha},
    { token:null, errorMessage: '', favs:'', serverResponse:false, verified:{status: false, _id:"", id: ""} }
);
