import Header from '../components/Header'
import Meta from '../components/Meta'

const About = () => {

  return (
    <div style={{marginTop: "90px"}}>
      <Meta title={"Pucho - About Us"}/>
      <h3>About Us</h3>
      <div>
        Pucho is a platform to connect people with local South Asian businesses. It enables users to efficiently and
        effortlessly search for a variety of ethnically and culturally rich services and products. Whether you are searching
        for vendors for a wedding, craving delicious Indian food, or searching for trusted South Asian professionals, we’re here
        to help!
      </div>

      <hr />

      <h3>Customers</h3>
      <div>
          Search our comprehensive directory of South Asian businesses. Efficiently research, discover and contact countless
          number of South Asian businesses and professionals in order to make the best decision for you. Our directory features
          every sort of business you could imagine – from renovation contractors to lawyers to wedding vendors.
      </div>
      <hr />
      <h3>Businesses</h3>
      <div>
        Get exposure to thousands of customers through our preferred Pucho listings. This feature highlights your listing,
        thereby increasing traffic, leads & revenue for your South Asian business.
      </div>
      <hr />
      <h3>Mission Statement</h3>
      <div>
        Pucho’s mission is to connect people to South Asian businesses in a simple, informative and efficient manner.
        We know your time is valuable – that’s why we do the hard part for you. With a touch of a button you could browse &
        contact numerous South Asian businesses. Whether you’re looking for quality service or for a good bargain, we’ve got
        you covered.
      </div>
    </div>
  )
}

export default About
