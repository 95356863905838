import { Col, Form, Button, FormControl, Container, Row, Card, ListGroup } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa';
import { useContext, useEffect, useState, useRef } from 'react';
import Cookies from 'universal-cookie';
import   { useHistory } from "react-router-dom";

const Autocomplete = ({city, setShow, mobile}) => {
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const cookies = new Cookies();
  const [showCategories, setShowCategories] = useState(false);
  const [categoryQuery, setCategoryQuery] = useState("");
  const searchRef = useRef(null);
  useEffect( async() => {
      setCategories(await cookies.get('categories'));
      function handler(event) {
          // change starts here
          if(!searchRef.current?.contains(event.target)) {
              setShowCategories(false);
          } else {
            setShowCategories(true);
          }
          // change starts here
      }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)

    }, []);

    const filteredCategories = categories.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(categoryQuery.toLowerCase()) > -1
    );

    function handleCategory(result) {
      setCategoryQuery("");
      history.push("/explore/" + result + "/" + city);
      setShowCategories(false);
      if (mobile) { setShow(false) }
    }

    function handleCategorySearch(result) {
      setCategoryQuery(result);
      setShowCategories(true);
    }


  return (
    <Container>
          <Form>
            <FormControl
              type="search"
              placeholder="Search"
              value={categoryQuery}
              aria-label="Where"
              onChange={(e)=>handleCategorySearch(e.target.value)}
            />
            { showCategories &&
              <Card style={{position: "absolute", textAlign: "left", marginLeft: "16px", zIndex: 99}}>
                { categoryQuery !== "" &&
                  <Card.Body>
                          <ListGroup variant="flush">
                            { filteredCategories.slice(0, 5).map((l,i) => (
                              <ListGroup.Item key={i}><Button variant="light" size="sm" onClick={()=>handleCategory(l)}>{l}</Button></ListGroup.Item>
                            ))
                            }
                          </ListGroup>
                  </Card.Body>
                }
              </Card>
            }
          </Form>
      </Container>
  )
}

export default Autocomplete
