import { Container, Col, Card, Form, Button, Row, Image } from 'react-bootstrap'
import Meta from '../components/Meta'
import { LinkContainer } from 'react-router-bootstrap'
import { GoogleLogin } from 'react-google-login';
import { useHistory } from "react-router-dom";
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../context/authContext';

const Signin = () => {
  const { state, signin, clearErrorMessage, signinGoogle } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  const history = useHistory();

  async function responseGoogle(token) {
    const response = await signinGoogle({token: token.tokenId});
    console.log(response);
    if (response.verified) {
      history.push("/myaccount");
    } else {
      alert("Something went wrong")
    }
  }

  async function handleSubmit(event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    clearErrorMessage();

    if (form.checkValidity() === false) {
      alert ("Please provide your username and password")
    } else {
        const response = await signin({email: form.email.value, password: form.password.value});
        if (response === "Google") {
          alert("You signed up for Pucho using Google! Please login using the Google sign in method");
        } else {
          if (response.valid) {
            if (response.verified) {
              history.push("/myaccount");
            } else {
              history.push("/verify");
            }
          }
          else {
            alert("Your email or password are incorrect.")
          }
        }
    }
    setValidated(true);
  }
  return (
    <Row className="text-center" style={{marginTop:"90px"}}>
    <Meta title="PUCHO - Sign In"/>
    <Col sm={8}>
      <Image style={{ objectFit: "cover", width:"100%" }} fluid src="signin.jpg" />
    </Col>
    <Col sm={4}>
        <Card>
          <Card.Body>
           <Card.Title className="pb-3"><h1>Sign In</h1></Card.Title>
           <hr />
           <Container className="pt-3 pb-3">
             <GoogleLogin
               clientId="692515347689-9opro7d0dsedfdlk4nhufoetoo8qb1rv.apps.googleusercontent.com"
               buttonText="Sign In with Google"
               onSuccess={responseGoogle}
               onFailure={responseGoogle}
               cookiePolicy={'single_host_origin'}
               isSignedIn={false} />
          </Container>

           <hr />
           Or sign in with your email and password
           <Form className="pt-5" noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail" controlId="validationCustom01">
                <Form.Label className="fw-bold">Email address</Form.Label>
                <Form.Control required name="email" type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3 pt-3" controlId="formBasicPassword" controlId="validationCustom02">
                <Form.Label className="fw-bold">Password</Form.Label>
                <Form.Control required name="password" type="password" placeholder="Password" />
              </Form.Group>
              <Container className="pt-3">
                By signing up, you agree to our Terms of Service and Privacy Policy.
              </Container>
              <Container className="pt-2">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Container>
            </Form>

              <Row className="pt-5">
                <Card.Link href="/signup">Sign up now!</Card.Link>
              </Row>
              <Row className="pt-2">
                <Card.Link href="/forgotpassword">Forgot your password?</Card.Link>
              </Row>
              </Card.Body>
              <Container className="pt-5 pb-5" />
          </Card>
        </Col>
    </Row>
  )
}

export default Signin
