import { Card, Row, Col, CardGroup } from 'react-bootstrap'
import { useHistory } from "react-router-dom";

import {
    FaCameraRetro,
    FaVideo,
    FaPlaceOfWorship,
    FaUtensils,
    FaEye,
    FaSlideshare,
    FaHammer,
    FaHome,
    FaCut,
    FaShoppingBag,
    FaUserTie,
    FaCar,
    FaEllipsisH,
    FaGavel,
    FaPlaneDeparture
  } from 'react-icons/fa'

export const VendorCard = (props) => {
  const history = useHistory();

  function handleClick(props) {
    history.push("/explore/"+props+"/all");
  }

  return (
    <div>

      <Card onClick={()=>handleClick(props.category)} className="bg-dark text-white text-center" tag="a" style={{ cursor: "pointer" }}>
          <Card.Img src={props.img} alt="Card image" />
          <Card.ImgOverlay>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.text}</Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
  )
}

export const IconCards = () => {
  const history = useHistory();
  async function handleClick(category) {
    history.push("/explore/" + category+ "/Toronto (GTA)");
  }
  return (
    <div>
    <CardGroup>
      <Card tag="a" onClick={() => handleClick("Restaurant")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center" >
          <Card.Text><FaUtensils size={50} /> </Card.Text>
          <Card.Text>Restaurants</Card.Text>
        </Card.Body>
      </Card>

      <Card tag="a" onClick={() => handleClick("Renovation")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaHammer size={50} /> </Card.Text>
          <Card.Text>Home Services</Card.Text>
        </Card.Body>
      </Card>

      <Card tag="a" onClick={() => handleClick("Real Estate")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaHome size={50} /> </Card.Text>
          <Card.Text>Real Estate Agents</Card.Text>
        </Card.Body>
      </Card>

      <Card tag="a" onClick={() => handleClick("Beauty Salon")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaCut size={50} /> </Card.Text>
          <Card.Text>Salon Services</Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
    <CardGroup>
      <Card tag="a" onClick={() => handleClick("Lawyer")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaGavel size={50} /> </Card.Text>
          <Card.Text>Law Firms</Card.Text>
        </Card.Body>
      </Card>
      <Card tag="a" onClick={() => handleClick("Travel Agency")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaPlaneDeparture size={50} /> </Card.Text>
          <Card.Text>Travel Agency</Card.Text>
        </Card.Body>
      </Card>
      <Card tag="a" onClick={() => handleClick("Auto Mechanic")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaCar size={50} /> </Card.Text>
          <Card.Text>Automotive</Card.Text>
        </Card.Body>
      </Card>
      <Card tag="a" onClick={() => handleClick("all")} className="ml-1 mr-1" style={{ cursor: "pointer" }}>
        <Card.Body className = "text-center">
          <Card.Text><FaEllipsisH size={50} /> </Card.Text>
          <Card.Text>More Categories</Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
  </div>
  )
}
