import { Form, Row, Col, Card, Modal, Button } from 'react-bootstrap'
import { useState } from 'react';
import {
    FaTimesCircle,
  } from 'react-icons/fa'

export const PhotoPicker = ({photos, setPhotos, edit, photosURL, setPhotosURL},) => {
  const [show, setShow] = useState(false);
  const [curPhoto, setCurPhoto] = useState();
  function handleDelete() {
    setShow(false);
    if (curPhoto === 1) { setPhotos({...photos, photo1: ' '}); setPhotosURL({...photosURL, photo1: ' '})};
    if (curPhoto === 2) { setPhotos({...photos, photo2: ' '}); setPhotosURL({...photosURL, photo2: ' '})};
    if (curPhoto === 3) { setPhotos({...photos, photo3: ' '}); setPhotosURL({...photosURL, photo3: ' '})};
  }
  function handleShow () {
    setShow(false);
  }

  const removePhoto = (photoNum) => {
  if (edit === true) {
    setCurPhoto(photoNum);
    setShow(true);
  } else {
    if (photoNum === 1) { setPhotos({...photos, photo1: ' '}); setPhotosURL({...photosURL, photo1: ' '})};
    if (photoNum === 2) { setPhotos({...photos, photo2: ' '}); setPhotosURL({...photosURL, photo2: ' '})};
    if (photoNum === 3) { setPhotos({...photos, photo3: ' '}); setPhotosURL({...photosURL, photo3: ' '})};
  }
};
  return(
    <>
    <Row>
      <Col>
        <Card style={{height: "400px"}}>
          <Card.Img variant="top" src={photosURL.photo1} style={{height: "300px", objectFit: "contain"}}/>
          <Card.Body className="text-center">
            { photosURL.photo1 === ' ' ?
              <Form.Group controlId="formFileLg1" className="mb-3 text-center">
                <Form.Control
                   type="file"
                   accept="image/*"
                   size="lg"
                   onChange={e => {
                      setPhotos({...photos, photo1: e.target.files[0]});
                      setPhotosURL({...photosURL, photo1:(URL.createObjectURL(e.target.files[0]))});
                    }}/>
              </Form.Group> :
              <FaTimesCircle color="red" style={{cursor: "pointer"}} onClick={()=>removePhoto(1)} size={40} />
            }
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card style={{height: "400px"}}>
          <Card.Img variant="top" src={photosURL.photo2} style={{height: "300px", objectFit: "contain"}}/>
          <Card.Body className="text-center">
            { photosURL.photo2 === ' ' ?
              <Form.Group controlId="formFileLg2" className="mb-3 text-center">
                <Form.Control
                   type="file"
                   accept="image/*"
                   size="lg"
                   onChange={e => {
                      setPhotos({...photos, photo2: e.target.files[0]});
                      setPhotosURL({...photosURL, photo2:(URL.createObjectURL(e.target.files[0]))});
                    }}/>
              </Form.Group> :
              <FaTimesCircle color="red" style={{cursor: "pointer"}} onClick={()=>removePhoto(2)} size={40} />
            }
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card style={{height: "400px"}}>
          <Card.Img variant="top" src={photosURL.photo3} style={{height: "300px", objectFit: "contain"}}/>
          <Card.Body className="text-center">
            { photosURL.photo3 === ' ' ?
              <Form.Group controlId="formFileLg3" className="mb-3 text-center">
                <Form.Control
                   type="file"
                   accept="image/*"
                   size="lg"
                   onChange={e => {
                      setPhotos({...photos, photo3: e.target.files[0]});
                      setPhotosURL({...photosURL, photo3:(URL.createObjectURL(e.target.files[0]))});
                    }}/>
              </Form.Group> :
              <FaTimesCircle color="red" style={{cursor: "pointer"}} onClick={()=>removePhoto(3)} size={40} />
            }
          </Card.Body>
        </Card>
      </Col>
    </Row>

    <Modal show={show} onHide={handleShow}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you would like to delete this photo?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export const LogoPicker = ({logo, setLogo, edit, logoURL, setLogoURL}) => {
  const [show, setShow] = useState(false);
  function handleDelete() {
    setShow(false);
    setLogoURL(' ');
    setLogo(' ');
  }
  const handleShow = () => setShow(true);

  const removeLogo = () => {
  if (edit === true) {
    setShow(true);
  } else {
    setLogoURL(' ');
    setLogo(' ');
  }
};
  return(
    <>
    <Row>
      <Col>
        <Card style={{width: "18rem", height: "300px"}}>
          <Card.Img variant="top" src={logoURL} style={{height: "200px", objectFit: "contain"}}/>
          <Card.Body className="text-center">
            { logoURL === ' ' | logoURL ==='' ?
              <Form.Group controlId="formFileLg4" className="mb-3 text-center">
                <Form.Control
                   type="file"
                   accept="image/*"
                   size="lg"
                   onChange={e => {
                      setLogoURL(URL.createObjectURL(e.target.files[0]))
                      setLogo(e.target.files[0]);
                    }}/>
              </Form.Group> :
              <FaTimesCircle color="red" style={{cursor: "pointer"}} onClick={()=>removeLogo()} size={40} />
            }
          </Card.Body>
        </Card>
      </Col>
    </Row>

    <Modal show={show} onHide={handleShow}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you would like to delete this logo?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default PhotoPicker
