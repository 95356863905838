import Meta from '../components/Meta'
import { Table, Container, Col, Card, FormControl, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap'

const Privacy = () => {

  return (
    <Container style={{marginTop: "90px"}}>
      <Meta title={"Pucho - Privacy Policy"}/>
      <h3>Privacy Policy</h3>
      

    </Container>
  )
}

export default Privacy
