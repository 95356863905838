import { Container, Row, Col, Button, Card, Tabs, Tab, Image, ListGroup, Modal, Badge, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../context/authContext';
import {Context as ListingContext} from '../context/listingContext';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { FaPlus, FaEdit, FaTrash, FaEye } from 'react-icons/fa';

const MyAccount = () => {
  const { state: auth, signout, authCheck, changePassword, deleteAccount } = useContext(AuthContext);
  const { state, get_listings, delete_listing, clearListing, get_listing, cloudinaryDelete } = useContext(ListingContext);
  const cookies = new Cookies();
  const history = useHistory();
  const token = cookies.get('token');
  const email = cookies.get('email');
  const _id = cookies.get('id');
  const method = cookies.get('method');
  const [confirm, setConfirm] = useState(false);
  const [passModal, setPassModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [key, setKey] = useState("home");
  const [curID, setCurID] = useState('');
  const [curName, setCurName] = useState('');
  const [passwordValid, setPasswordValid] = useState();
  const [password2Valid, setPassword2Valid] = useState();
  const [password3Valid, setPassword3Valid] = useState();
  const [google, setGoogle] = useState(false);

  useEffect( async() => {
      const status = await authCheck();
      if (status !== "") {
        await get_listings();
        if (method === "Google") {
          setGoogle(true);
        }
      } else {
        history.push("/login")
      }
    }, []);
  function logout() {
    signout();
    history.push("/")
  }
  async function edit(_id) {
    await clearListing();
    await get_listing({_id});
    history.push("/addlisting")
  }
  async function addListing() {
    await clearListing();
    history.push("/addlisting");
  }
  function remove(id, name, type) {
    setCurID(id);
    setCurName(name + " - " + type);
    setConfirm(true);
  }
  function open(id, name) {
    history.push("/listing/" + id+"/"+name.replace(/\s+/g, '-').toLowerCase())
  }
  async function handleDelete() {
    const listing = await get_listing({_id: curID});
    listing.photoURL.map(async(l,i) => {
      await deleteImage(l);
    });
    deleteImage(listing.logo);
    await delete_listing({_id: curID});
    setConfirm(false);
    await get_listings();
   }
  function handleShow() {
    setConfirm(false);
  }
  const deleteImage = async (file) => {
    var publicID = file.split('/');
    var curPhoto = publicID[publicID.length - 1].split('.');
    await cloudinaryDelete(curPhoto[0]);
  }

  function handlePassShow() {
    setPassModal(false);
    setKey("home");
  }

  function handleDeleteShow() {
    setDeleteModal(false);
  }

  function handleAccountDelete(event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (state.data.length !== 0) {
      alert('Please delete all listings before deleting your account')
    } else {
      if (form.deleteEmail.value === email) {
        setDeleteModal(true);
      } else {
        alert('Please type your email account correctly')
      }
    }
  }

  async function confirmedDelete() {
    const response = await deleteAccount({_id});
    if (response) {
      alert ("Your account has been deleted");
      setDeleteModal(false);
      signout();
      history.push("/")
    }
  }

  async function handleSubmit(event) {

      event.preventDefault();
      event.stopPropagation();
      if (passwordValid && password2Valid) {
        const response = await changePassword({email:email, password: oldPassword, newPassword: newPassword});
        if (response) {
          setPassModal(true);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Please complete all fields")
      }
  }

  function passwordChange1(event) {
    var check = event.target.value
    setPasswordValid(false);
    setNewPassword(check);
    if (check.length < 8) return
    if(/\d/.test(check)==false) return
    if (check.toUpperCase() == check) return
    if (check.toLowerCase() == check) return
    setPasswordValid(true);
  }
  function passwordChange2(event) {
    var check = event.target.value
    setPassword2Valid(false);
    if (newPassword == check) {
      setPassword2Valid(true);
    }
  }
  return (
    <Container style={{marginTop: "90px"}}>
      <Meta title="PUCHO - My Account"/>
      <Card>
          <Card.Header><h1>My Account</h1></Card.Header>
          <Image src="lang-fade.png" fluid />
          <Container className="pt-3">
            <h4>{email}</h4>
            <Button variant = "danger" onClick={logout}>Logout</Button>
          </Container>
          <Card.Body>
            <Tabs defaultActiveKey="home" id="accountTabs" activeKey={key} className="mb-3" onSelect={(k) => setKey(k)}>
              <Tab eventKey="home" title="My Listings">
                <Container>
                  <Button onClick={addListing} variant="success"><FaPlus /> Add a Listing</Button>
                </Container>
                <Container>
                  <ListGroup className="pt-3">
                  { state.data != null ?
                    state.data.map((l, i) => (
                      <>
                        <ListGroup.Item>
                          <Row  key={i}>
                            <Col>
                              <h4>
                                {l.name}
                              </h4>
                              {l.type}&emsp;
                              { l.approved ?
                                <Badge pill style={{backgroundColor:"green", fontSize:"11px"}}>Approved</Badge>
                                :
                                <Badge pill style={{backgroundColor:"red", fontSize:"11px"}}>Pending Approval</Badge>
                              }
                            </Col>
                            <Col style={{textAlign: "right"}}>
                              <Button variant="outline-dark" onClick={() => open(l._id, l.name)}><FaEye size={30} /></Button>
                              {' '}
                              <Button variant="outline-dark" onClick={() => edit(l._id)}><FaEdit size={30} /></Button>
                              {' '}
                              <Button variant="outline-dark" onClick={() => remove(l._id, l.name, l.type)}><FaTrash size={30} color="red" /></Button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </>
                    )) : <></> }
                  </ListGroup>
                </Container>
              </Tab>
              <Tab eventKey="password" title="Change Password" disabled={google}>
                <Container className="text-center">
                  <Form className="pt-2" noValidate onSubmit={handleSubmit} style={{width: "300px"}}>

                     <Form.Group className="mb-3 pt-3" controlId="formBasicPassword">
                       <Form.Control required name="oldPassword" type="password" placeholder="Current Password" onChange={(e)=>setOldPassword(e.target.value)}/>
                       <br />
                       <Form.Control required name="password" type="password" placeholder="New Password" isInvalid={!passwordValid} isValid={passwordValid} onChange={passwordChange1} />
                       <br />
                       <Form.Control required type="password" placeholder="Reenter New Password" isInvalid={!password2Valid} isValid={password2Valid} onChange={passwordChange2} />
                       <Form.Text className="text-muted">
                         8-character minimum with a capital letter and number.
                       </Form.Text>
                     </Form.Group>

                     <Container className="pt-2">
                       <Button variant="primary" type="submit">
                         Submit
                       </Button>
                     </Container>

                   </Form>
                </Container>
              </Tab>
              <Tab eventKey="delete" title="Delete Account">
                <Container className="pt-3">
                  <p>Are you sure you want to delete your account? We'd be sad to see you go!</p>
                  <p> If you would like to proceed with deleting your account, please delete any listings you currently have posted.</p>

                  <Form  onSubmit={handleAccountDelete}>
                    <Form.Group className="mb-3" controlId="deleteEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control required style={{width: "300px"}} type="email" placeholder="Enter email" />
                      <Form.Text className="text-muted">
                        To confirm, please type in your email address.
                      </Form.Text>
                    </Form.Group>
                    <Button variant="danger" type="submit">
                      Delete
                    </Button>
                  </Form>
                </Container>
              </Tab>
            </Tabs>
          </Card.Body>
      </Card>

      <Modal show={confirm} onHide={handleShow} className="text-center">
        <Modal.Body>
              <p>Are you sure you would like to delete the following listing?</p>
              <p>{curName}</p>
        </Modal.Body>
        <Modal.Footer style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button variant="secondary" onClick={handleShow}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={passModal} onHide={handlePassShow} className="text-center">
        <Modal.Body>
              Your password has been changed. Please use your new password when logging in.
        </Modal.Body>
        <Modal.Footer style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button variant="outline-dark" onClick={handlePassShow}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteModal} onHide={handleDeleteShow} className="text-center">
        <Modal.Body>
              <p>By pressing delete, your account will be deleted.</p>
        </Modal.Body>
        <Modal.Footer style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button variant="secondary" onClick={handleDeleteShow}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmedDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default MyAccount
