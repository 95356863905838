import Meta from '../components/Meta'
import { Container } from 'react-bootstrap'
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

const Terms = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  return (
    <Container style={{marginTop: "90px"}}>
      <Meta title={"Pucho - Terms of Service"}/>
      <h3>Terms of Service</h3>
      By listing your business on Pucho, you are bound to the following terms:

      <Document
        file="Pucho_Corporation_Terms_of_Service.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
</Container>
  )
}

export default Terms
