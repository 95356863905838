import { Col, Table, Row } from 'react-bootstrap'

const Hours = (props) => {
  return (
    <div className="pt-3">
      <Row>
        <Col>
          <h4><strong>Hours</strong></h4>
          <Table hover size="sm">
            <tbody>
              <tr>
                { props.hours.monday.isOpen ?
                  <>
                    <td>Monday</td>
                    <td style={{textAlign:"right"}}>{props.hours.monday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.monday.close}</td>
                  </>
                :
                  <>
                    <td>Monday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
              <tr>
                { props.hours.tuesday.isOpen ?
                  <>
                    <td>Tuesday</td>
                    <td style={{textAlign:"right"}}>{props.hours.tuesday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.tuesday.close}</td>
                  </>
                :
                  <>
                    <td>Tuesday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
              <tr>
                { props.hours.wednesday.isOpen ?
                  <>
                    <td>Wednesday</td>
                    <td style={{textAlign:"right"}}>{props.hours.wednesday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.wednesday.close}</td>
                  </>
                :
                  <>
                    <td>Wednesday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
              <tr>
                { props.hours.thursday.isOpen ?
                  <>
                    <td>Thursday</td>
                    <td style={{textAlign:"right"}}>{props.hours.thursday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.thursday.close}</td>
                  </>
                :
                  <>
                    <td>Thursday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
              <tr>
                { props.hours.friday.isOpen ?
                  <>
                    <td>Friday</td>
                    <td style={{textAlign:"right"}}>{props.hours.friday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.friday.close}</td>
                  </>
                :
                  <>
                    <td>Friday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
              <tr>
                { props.hours.saturday.isOpen ?
                  <>
                    <td>Saturday</td>
                    <td style={{textAlign:"right"}}>{props.hours.saturday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.saturday.close}</td>
                  </>
                :
                  <>
                    <td>Saturday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
              <tr>
                { props.hours.sunday.isOpen ?
                  <>
                    <td>Sunday</td>
                    <td style={{textAlign:"right"}}>{props.hours.sunday.open}</td>
                    <td className="text-center">-</td>
                    <td>{props.hours.sunday.close}</td>
                  </>
                :
                  <>
                    <td>Sunday</td>
                    <td />
                    <td className="text-center">Closed</td>
                    <td />
                  </>
                }
              </tr>
            </tbody>
          </Table>
        </Col>
      <Col xs={5}>
        <h4></h4>
      </Col>
    </Row>
    </div>
  )
}

export default Hours
