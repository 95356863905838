import { Container, Button, Form, Modal } from 'react-bootstrap'
import Meta from '../components/Meta'
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../context/authContext';
import { useHistory } from "react-router-dom";

const Forgot = () => {
  const { state, verify, verifyReset, clearErrorMessage, forgotPassword } = useContext(AuthContext);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  async function handleSubmit(event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      alert ("Please provide your username and password")
    } else {
        const response = await forgotPassword({email: form.email.value});
        if (response) {
          setShow(true);
        }
    }
    setValidated(true);
  }

  function handleClose() {
    history.push('/');
  }
  function handleShow() {
    setShow(false);
  }
  return (
    <Container className="text-center" style={{marginTop:"200px"}}>
    <Meta title="PUCHO - Forgot your Password"/>
      <h2>Forgot Password</h2>
      <Container className="pt-4" style={{display: "flex", justifyContent: "center", width: "50%"}}>
        <Form className="pt-5" onSubmit={handleSubmit}>
           <Form.Group className="mb-3" controlId="formBasicEmail" controlId="validationCustom01">
             <Form.Label className="fw-bold">Email address</Form.Label>
             <Form.Control required name="email" type="email" placeholder="Enter email" />
           </Form.Group>

           <Container className="pt-3">
              If you have an account with Pucho, you will receive an email with a password reset link.
           </Container>
           <Container className="pt-5">
             <Button variant="danger" type="submit">
               Reset Password
             </Button>
           </Container>
         </Form>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Pucho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <p>If your email is registered with Pucho, a password rest link has been sent to your email that will expire in 2 hours.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default Forgot
