import { Card, Row, Col, Container, ListGroup, Button } from 'react-bootstrap'
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaLinkedin,
  } from 'react-icons/fa'
  import {
    isDesktop,
    isMobile
  } from "react-device-detect";

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='text-center text-capitalize' style={{backgroundColor: "white"}}>
        <hr />
        { isDesktop ?
          <Container>
            <Row>
              <Col>
                <ListGroup variant="flush">
                  <ListGroup.Item action href="/about">About Us</ListGroup.Item>
                  <ListGroup.Item action href="/termsofservice">Terms of Service</ListGroup.Item>
                  <ListGroup.Item action href="/contentguidelines">Content Guidelines</ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <ListGroup variant="flush">
                  <ListGroup.Item action href="/contact">Contact Us</ListGroup.Item>
                  <ListGroup.Item action href="/help">Help</ListGroup.Item>
                  <ListGroup.Item action href="/privacy">Privacy Policy</ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    Follow us on:
                    <div className="pt-2">
                      <a href="https://business.facebook.com/pucho.corp/community/" className="text-black" target="_blank" rel="noopener noreferrer"><FaFacebook  size={30} /></a>
                      &ensp;&ensp;
                      <a href="https://www.instagram.com/pucho.corp/" className="text-black" target="_blank" rel="noopener noreferrer"><FaInstagram  size={30} /></a>
                      &ensp;&ensp;
                      <a href="https://twitter.com/pucho_corp?t=YJ0fEoTx7IYFbSg48M5Plw&s=08" className="text-black" target="_blank" rel="noopener noreferrer"><FaTwitter  size={30} /></a>
                      &ensp;&ensp;
                      <a href="https://www.linkedin.com/company/pucho-corporation/about/" className="text-black" target="_blank" rel="noopener noreferrer"><FaLinkedin  size={30} /></a>
                    </div>
                    <br />
                    #pucho
                    </Card.Body>
                </Card>
              </Col>
            </Row>
            <hr />
            <p>copyright Pucho Corp &copy; {year}</p>
          </Container>
          :
          <Container>
            <Row>
            <ListGroup variant="flush" style={{fontSize: "12px"}}>
              <ListGroup.Item action href="/about">About Us</ListGroup.Item>
              <ListGroup.Item action href="/termsofservice">Terms of Service</ListGroup.Item>
              <ListGroup.Item action href="/ContentGuidelines">Content Guidelines</ListGroup.Item>
              <ListGroup.Item action href="/contact">Contact Us</ListGroup.Item>
              <ListGroup.Item action href="/help">Help</ListGroup.Item>
              <ListGroup.Item action href="/privacy">Privacy Policy</ListGroup.Item>
              <ListGroup.Item>
              Follow us on:
                <div className="pt-2">
                  <a href="https://business.facebook.com/pucho.corp/community/" className="text-black" target="_blank" rel="noopener noreferrer"><FaFacebook  size={30} /></a>
                  &ensp;&ensp;
                  <a href="https://www.instagram.com/pucho.corp/" className="text-black" target="_blank" rel="noopener noreferrer"><FaInstagram  size={30} /></a>
                  &ensp;&ensp;
                  <a href="https://twitter.com/pucho_corp?t=YJ0fEoTx7IYFbSg48M5Plw&s=08" className="text-black" target="_blank" rel="noopener noreferrer"><FaTwitter  size={30} /></a>
                  &ensp;&ensp;
                  <a href="https://www.linkedin.com/company/pucho-corporation/about/" className="text-black" target="_blank" rel="noopener noreferrer"><FaLinkedin  size={30} /></a>
                </div>
              </ListGroup.Item>
            </ListGroup>
            </Row>
          </Container>
        }
      </footer>
    </>
  )
}

export default Footer
