import { Card, Row, Col, Button } from 'react-bootstrap'
import { FaTimesCircle } from 'react-icons/fa'
import Autocomplete from '../components/Autocomplete'
import   { useHistory } from "react-router-dom";

const Filter = ({cities, category, city, mobile, setShow}) => {
  const history = useHistory({forceRefresh:true});
  function handleLink(link) {
    history.push(link);
    setShow(false);
  }
  return (
    <Col sm={3}>
    { !mobile &&
        <Card>
          <Card.Body>
            <Card.Title className="text-center"><h4>Current Filters</h4></Card.Title>
            <hr />
            {category === "all" ? "" :
              <Row>
                <Col md="5" style={{textAlign: "right"}}>
                  <strong>Category:</strong></Col><Col md="7">{category + '  '}
                  <a href={"/explore/all/"+city} style={{color: "red"}}><FaTimesCircle/></a></Col>
              </Row>}
              {city === "all" ? "" :
                <Row>
                  <Col md="5" style={{textAlign: "right"}}>
                    <strong>City:</strong></Col><Col md="7">{city + '  '}
                    <a href={"/explore/"+category+"/all"} style={{color: "red"}}><FaTimesCircle/></a></Col>
                </Row>}
          </Card.Body>
        </Card>
      }
      <div className="pt-4" />
      <Card>
        <Card.Body>
          <Card.Title className="text-center"><h4>Categories</h4></Card.Title>
          <hr />
          <Autocomplete city={city} setShow={setShow} mobile/>
          { !mobile &&
            <>
            <div className="pt-3 text-center" style={{fontWeight: "bold"}}> Popular Categories:</div>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="pt-2"><Button style={{width: "75%"}} onClick={()=>handleLink("/explore/Photographer/" + city)} variant="outline-dark">Photographer</Button></Row>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="pt-2"><Button style={{width: "75%"}} onClick={()=>handleLink("/explore/Videographer/" + city)} variant="outline-dark">Videographer</Button></Row>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="pt-2"><Button style={{width: "75%"}} onClick={()=>handleLink("/explore/Make%20Up%20Artist%20(MUA)/" + city)} variant="outline-dark">Make Up Artist (MUA)</Button></Row>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="pt-2"><Button style={{width: "75%"}} onClick={()=>handleLink("/explore/Restaurant/" + city)} variant="outline-dark">Restaurant</Button></Row>
            </>
          }
        </Card.Body>
      </Card>
      <div className="pt-4" />
      <Card className="text-center">
        <Card.Body style={{textAlign: "center"}}>
          <Card.Title className="text-center"><h4>Regions</h4></Card.Title>
          <hr />
          <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Button size="sm" style={{width: "75%"}} onClick={()=>handleLink("/explore/" + category +"/all")} variant={city === "all" ? "dark" : "outline-dark"}>All</Button></Row>
          {
            cities.map((l,i) => (
              <Row key={i} style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="pt-1">
                <Button size="sm" style={{width: "75%"}} key={i} onClick={()=>handleLink("/explore/"+ category + "/" + l.city)} variant={l.city === city ? "dark" : "outline-dark"}>{l.city}</Button>
              </Row>
            ))
          }
        </Card.Body>
      </Card>

    </Col>
  )
}

export default Filter
